import React, { useEffect } from 'react';
import Sdk from 'api.digitalpages.module.sdk.api';
import { Route, Switch, useHistory } from 'react-router-dom';

import {
  ViewAccessExternalList,
  ViewEditAccessExternal,
  ViewNewAccessExternal,
  ViewList,
  ViewDashboard,
  ViewNewContent,
  ViewIframe,
  ViewProductList,
  // ViewProductNew,
  ViewProductNewV,
  ViewGroupsList,
  ViewCoursesList,
  ViewUsersList,
  ViewProjectList,
  ViewActiveProjectUserList,
  ViewDirectoryUserList,
  ViewDevicesList,
  ViewIotList,
  ViewGroupsManagement,
  ViewCourseManagement,
  ViewDeviceManagement,
  ViewGroupIotManagement,
  ViewNewUser,
  ViewEditUser,
  ViewNewContainer,
  ViewProfilesList,
  ViewAdministratorsList,
  ViewNewProfile,
  ViewAdministratorsNew,
  ViewAdministratorsEdit,
  ViewEditTree,
  ViewEditTreeV2,
  ViewListTree,
  ViewNewCourse,
  ViewNewOffer,
  ViewEditOffer,
  ViewEditProject,
  ViewNewSchema,
  ViewNewSchemaProject,
  ViewNewProject,
  ViewEntitiesList,
  ViewProductsList,
  ViewTransactionsList,
  // ViewNewProduct,
  ViewEditProduct,
  ViewEditReview,
  ViewListRevision,
  ViewListSubmission,
  ViewNewReview,
  ViewSchemasList,
  ViewNewEntity,
  ViewEditEntity,
  ViewEditLayoutConfiguration,
  ViewCertificatesList,
  ViewEditCertificate,
  ViewForum,
  ViewListRubric,
  ViewNewRubric,
  ViewGroupList,
  ViewNewGroupEntity,
  ManagedContentList,
  ManagedContentNew,
  ManagedContentEdit,
  ViewCorrectContent
  
} from '../views';

import ViewLogin from '../views/login/login';
import ContextRoute from './context-route';
import RouteContext from '../contexts/route-context';

export default function Routes() {
  const history = useHistory();

  useEffect(() => {
    window.addEventListener('@rdpmanager-admin.event(logout)', async () => {
      await Sdk.Api.authorization.logout();
      history.push('/login');
    });

    Sdk.authorization.addStatusListen((authorization) => {
      if (authorization.authenticated == true) return;
      history.push('/login');
    });
  }, []);

  return (
    <Switch>
      <Route exact path="/login" component={ViewLogin} />
      <ContextRoute
        exact
        path="/"
        contextComponent={RouteContext}
        component={ViewCorrectContent}
      />
      <ContextRoute
        exact
        path="/dashboard/uid/:dashboardUid"
        contextComponent={RouteContext}
        component={ViewDashboard}
      />
      <ContextRoute
        exact
        path="/reference/name/:name"
        contextComponent={RouteContext}
        component={ViewIframe}
      />

      <ContextRoute
        exact
        path="/embed/:name"
        contextComponent={RouteContext}
        component={ViewIframe}
      />

      <ContextRoute
        exact
        path="/cms/news/group/uid/:groupUid"
        contextComponent={RouteContext}
        component={ViewList}
      />

      <ContextRoute
        exact
        path="/groups"
        contextComponent={RouteContext}
        component={ViewGroupList}
      />

      <ContextRoute
        exact
        path="/cms/news/route/uid/:routeUid"
        contextComponent={RouteContext}
        component={ViewList}
      />
      <ContextRoute
        exact
        path="/cms/news/route/uid/:routeUid/entity/uid/:entityUid"
        contextComponent={RouteContext}
        component={ViewNewContent}
      />

      {/* <ContextRoute
        exact
        path="/store/products"
        contextComponent={RouteContext}
        component={ViewProductList}
      /> */}
      {/* <ContextRoute
        exact
        path="/store/product/new"
        contextComponent={RouteContext}
        component={ViewProductNewV}
      /> */}

      {/* <ContextRoute
        exact
        path="/store/product/new"
        contextComponent={RouteContext}
        component={ViewProductNew}
      /> */}

      <ContextRoute
        exact
        path="/management/groups"
        contextComponent={RouteContext}
        component={ViewGroupsList}
      />
      <ContextRoute
        exact
        path="/management/groups/:groupUid"
        contextComponent={RouteContext}
        component={ViewGroupsManagement}
      />
      <ContextRoute
        exact
        path="/learning/courses"
        contextComponent={RouteContext}
        component={ViewCoursesList}
      />
      <ContextRoute
        exact
        path="/learning/courses/:courseUid"
        contextComponent={RouteContext}
        component={ViewCourseManagement}
      />
      <ContextRoute
        exact={true}
        path="/project/users"
        contextComponent={RouteContext}
        component={ViewActiveProjectUserList}
      />
      <ContextRoute
        exact={true}
        path="/directory/users"
        contextComponent={RouteContext}
        component={ViewDirectoryUserList}
      />
      <ContextRoute
        exact={true}
        path="/project"
        contextComponent={RouteContext}
        component={ViewProjectList}
      />
      <ContextRoute
        exact={true}
        path="/users/new"
        contextComponent={RouteContext}
        component={ViewNewUser}
      />
      <ContextRoute
        exact={true}
        path="/users/:userUid"
        contextComponent={RouteContext}
        component={ViewEditUser}
      />
      <ContextRoute
        exact={true}
        path="/profiles"
        contextComponent={RouteContext}
        component={ViewProfilesList}
      />
      <ContextRoute
        exact={true}
        path="/profiles/new"
        contextComponent={RouteContext}
        component={ViewNewProfile}
      />
      <ContextRoute
        exact={true}
        path="/administrators"
        contextComponent={RouteContext}
        component={ViewAdministratorsList}
      />
      <ContextRoute
        exact={true}
        path="/administrators/new"
        contextComponent={RouteContext}
        component={ViewAdministratorsNew}
      />
      <ContextRoute
        exact={true}
        path="/administrators/:uid"
        contextComponent={RouteContext}
        component={ViewAdministratorsEdit}
      />
      <ContextRoute
        exact={true}
        path="/devices/:uid"
        contextComponent={RouteContext}
        component={ViewDeviceManagement}
      />
      <ContextRoute
        exact={true}
        path="/devices"
        contextComponent={RouteContext}
        component={ViewDevicesList}
      />

      <ContextRoute
        exact={true}
        path="/iot/groups/schema/:schemaUid/entity/:uid"
        contextComponent={RouteContext}
        component={ViewGroupIotManagement}
      />
      <ContextRoute
        exact={true}
        path="/iot/groups"
        contextComponent={RouteContext}
        component={ViewIotList}
      />
      <ContextRoute
        exact={true}
        path="/container/new"
        contextComponent={RouteContext}
        component={ViewNewContainer}
      />
      <ContextRoute
        exact={true}
        path="/tree/new"
        contextComponent={RouteContext}
        component={ViewNewCourse}
      />
      <ContextRoute
        exact={true}
        path="/tree/:treeUid"
        contextComponent={RouteContext}
        component={ViewEditTreeV2}
      />

      {/* <ContextRoute
        exact={true}
        path="/tree/:treeUid"
        contextComponent={RouteContext}
        component={ViewEditTree} */}
      {/* /> */}

      <ContextRoute
        exact={true}
        path="/tree"
        contextComponent={RouteContext}
        component={ViewListTree}
      />
      <ContextRoute
        exact={true}
        path="/offer/new/:treeUid"
        contextComponent={RouteContext}
        component={ViewNewOffer}
      />
      <ContextRoute
        exact={true}
        path="/offer/:treeUid/:registerUid"
        contextComponent={RouteContext}
        component={ViewEditOffer}
      />
     
      <ContextRoute
        exact={true}
        path="/group/new"
        contextComponent={RouteContext}
        component={ViewNewSchemaProject}
      />
      <ContextRoute
        exact={true}
        path="/project/new"
        contextComponent={RouteContext}
        component={ViewNewProject}
      />
       <ContextRoute
        exact={true}
        path="/project/:projectUid"
        contextComponent={RouteContext}
        component={ViewEditProject}
      />
      <ContextRoute
        exact={true}
        path="/entities"
        contextComponent={RouteContext}
        component={ViewEntitiesList}
      />
      <ContextRoute
        exact={true}
        path="/products"
        contextComponent={RouteContext}
        component={ViewProductsList}
      />
      <ContextRoute
        exact={true}
        path="/transactions"
        contextComponent={RouteContext}
        component={ViewTransactionsList}
      />
      <ContextRoute
        exact={true}
        path="/product/new"
        contextComponent={RouteContext}
        component={ViewProductNewV}
      />
      {/* <ContextRoute
        exact={true}
        path="/product/new"
        contextComponent={RouteContext}
        component={ViewNewProduct}
      /> */}
      <ContextRoute
        exact={true}
        path="/products/:productUid/edit"
        contextComponent={RouteContext}
        component={ViewEditProduct}
      />
      <ContextRoute
        exact={true}
        path="/review/new"
        contextComponent={RouteContext}
        component={ViewNewReview}
      />
      <ContextRoute
        exact={true}
        path="/review/:flowUid/edit"
        contextComponent={RouteContext}
        component={ViewEditReview}
      />
      <ContextRoute
        exact={true}
        path="/reviews"
        contextComponent={RouteContext}
        component={ViewListRevision}
      />
      <ContextRoute
        exact={true}
        path="/submission"
        contextComponent={RouteContext}
        component={ViewListSubmission}
      />

      <ContextRoute
        exact={true}
        path="/schema/new"
        contextComponent={RouteContext}
        component={ViewNewSchema}
      />
      <ContextRoute
        exact={true}
        path="/entities"
        contextComponent={RouteContext}
        component={ViewEntitiesList}
      />
      <ContextRoute
        exact={true}
        path="/schema"
        contextComponent={RouteContext}
        component={ViewSchemasList}
      />
      <ContextRoute
        exact={true}
        path="/schema/:schemaId"
        contextComponent={RouteContext}
        component={ViewEntitiesList}
      />
      <ContextRoute
        exact={true}
        path="/schema/:schemaId/entities/new"
        contextComponent={RouteContext}
        component={ViewNewEntity}
      />
      <ContextRoute
        exact={true}
        path="/schema/:schemaId/entities/:entityId/edit"
        contextComponent={RouteContext}
        component={ViewEditEntity}
      />
      <ContextRoute
        exact={true}
        path="/settings/layout/edit"
        contextComponent={RouteContext}
        component={ViewEditLayoutConfiguration}
      />
      <ContextRoute
        exact={true}
        path="/certificates"
        contextComponent={RouteContext}
        component={ViewCertificatesList}
      />
      <ContextRoute
        exact={true}
        path="/certificates/:contentUid"
        contextComponent={RouteContext}
        component={ViewEditCertificate}
      />
      <ContextRoute
        exact={true}
        path="/forum"
        contextComponent={RouteContext}
        component={ViewForum}
      />

      <ContextRoute
        exact={true}
        path="/rubric"
        contextComponent={RouteContext}
        component={ViewListRubric}
      />
      <ContextRoute
        exact={true}
        path="/rubric/new"
        contextComponent={RouteContext}
        component={ViewNewRubric}
      />

      <ContextRoute
        exact={true}
        path="/group/:schemaUid"
        contextComponent={RouteContext}
        component={ViewNewGroupEntity}
      />

      <ContextRoute
        exact={true}
        path="/external"
        contextComponent={RouteContext}
        component={ViewAccessExternalList}
      />

      <ContextRoute
        exact={true}
        path="/external/new"
        contextComponent={RouteContext}
        component={ViewNewAccessExternal}
      />

      <ContextRoute
        exact={true}
        path="/external/:externalUid"
        contextComponent={RouteContext}
        component={ViewEditAccessExternal}
      />

      <ContextRoute
        exact={true}
        path="/managed/content/connector/uid/:connectorUid"
        contextComponent={RouteContext}
        component={ManagedContentList}
      />
      <ContextRoute
        exact={true}
        path="/managed/content/connector/uid/:connectorUid/new"
        contextComponent={RouteContext}
        component={ManagedContentNew}
      />
      <ContextRoute
        exact={true}
        path="/managed/content/connector/uid/:connectorUid/group/uid/:contentGroupUid"
        contextComponent={RouteContext}
        component={ManagedContentEdit}
      />

      {/* <ContextRoute exact={true} path="/usuarios" contextComponent={RouteContext} component={ViewDashboard} /> */}
      {/* <ContextRoute exact={true} path="/usuarios" contextComponent={RouteContext} component={ViewList} /> */}
      {/* <Route exact={true} path="/config/entidades" component={ViewList} />
      <Route exact={true} path="/config/rotas" component={ViewList} />
      <Route exact={true} path="/config/grupos" component={ViewList} /> */}

      {/*
      <ContextRoute exact={true} path="/content/usuarios" contextComponent={RouteContext} component={ViewIframe} />
      <ContextRoute exact={true} path="/content/produtos" contextComponent={RouteContext} component={ViewIframe} />
      <ContextRoute exact={true} path="/content/subprodutos" contextComponent={RouteContext} component={ViewIframe} />
      <ContextRoute exact={true} path="/content/contextos" contextComponent={RouteContext} component={ViewIframe} />
      <ContextRoute exact={true} path="/content/cursos" contextComponent={RouteContext} component={ViewIframe} />
      <ContextRoute exact={true} path="/content/banners" contextComponent={RouteContext} component={ViewIframe} />
      <ContextRoute exact={true} path="/content/relatorio" contextComponent={RouteContext} component={ViewIframe} />
      <ContextRoute exact={true} path="/content/grupos" contextComponent={RouteContext} component={ViewIframe} />

      <ContextRoute exact={true} path="/listagem/:group/:route/:entityId" contextComponent={RouteContext} component={ViewList} />
      <ContextRoute exact={true} path="/novo-conteudo/:group/:route/:entityId" contextComponent={RouteContext} component={ViewNewContent} />
      <ContextRoute exact={true} path="/edit-conteudo/:group/:route/registro/:noticeId" contextComponent={RouteContext} component={ViewEditContent} /> */}
    </Switch>
  );
}
