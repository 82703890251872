import React, { useState, useEffect, useMemo, useRef } from 'react';
import csvToJson from 'convert-csv-to-json';
import {
  Sidebar,
  Breadcrumb,
  Header,
  Footer,
  TableDynamic,
  MessageNoItemInTable,
  Pagination,
  Filter,
  PageContainer,
  LoadingFullscreen,
} from '../../components';
import Components from 'rdp-builder-components';
import Loading from '../../components/loading';
import { textFromUid } from '../../utils/textUtilities';
import { objectPaths } from '../../utils/formUtilities';

import { Redirect } from 'react-router-dom';
import $ from 'jquery';
import Sdk from 'api.digitalpages.module.sdk.api';

import { ContainerListUser } from './style';
import { toast } from 'react-toastify';
import { set } from 'lodash';

export default function ManagedContentList({ consumer, match, history }) {
  const [uploadCsvUploading, setUploadCsvUploading] = useState(false);
  const [uploadCsvSuccess, setUploadCsvSuccess] = useState(false);
  const [uploadCsvError, setUploadCsvError] = useState(false);
  const [csvInputRef, setCsvInputRef] = useState(React.createRef());

  const [contents, setContents] = useState(null);
  const [filters, setFilters] = useState(null);
  const [search, setSearch] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);
  const [lastRequest, setLastRequest] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [processing, setProcessing] = useState(false);
  const [totalResults, setTotalResults] = useState(null);
  const [previousPageQuery, setPreviousPageQuery] = useState(null);
  const [connector, setConnector] = useState(null);
  var teste = null;

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_managed_content'),
    });
  }, []);

  useEffect(() => {
    const connectorUid = match.params.connectorUid;
    const connector = Sdk.Api.authorization.activeProject.connectors.find(
      (p) => p.uid == connectorUid
    );

    setConnector(connector);
  }, [match]);

  useEffect(() => {
    setCurrentPage(1);
  }, [filters, search]);

  useEffect(() => {
    if (lastRequest) clearTimeout(lastRequest);
    setLastRequest(setTimeout(getData, 1000));
  }, [currentPage, filters, search, connector]);

  const getData = async () => {
    if (!connector) return;
    setProcessing(true);

    let url = `cms/v1.1/managed/content/connector/uid/${connector.uid}/management/available?page=${currentPage}&page_size=20`;

    if (filters) {
      for (const filter in filters) {
        const values = filters[filter].map((fv) => `${filter}=${fv}`);
        url += `&${values.join('&')}`;
      }
    }

    if (search) url += `&search=${search}`;

    const response = await Sdk.dynamic.bridge(url);

    response.result.forEach((data) => {
      var thumbUrl = Sdk.dynamic.bridgeUrl(
        `cms/v1.1/managed/content/group/uid/${data.uid}/management/thumb`
      );
      data.thumb_url = thumbUrl;
      data.properties = data.properties.sort(function(a, b){
        return a.name.localeCompare(b.name);
      });
    });

    setCurrentPage(response.current_page);
    setTotalPages(response.total_pages);
    setTotalResults(response.total_results);
    setContents(response.result);
    setProcessing(false);
  };

  const importCsvOnClick = () => {
    csvInputRef.current.click();
  };

  const onRefreshUsers = () => {
    setUploadCsvUploading(false);
    setUploadCsvSuccess(false);

    setTimeout(getData, 50);
  };

  const newContentGroup = () => {
    const redirectTo = `/managed/content/connector/uid/${connector.uid}/new`;

    setRedirectTo(redirectTo);
  };

  const deleteContent = async (uid) => {
    await toast.promise(
      Sdk.dynamic.bridge(
        `cms/v1.1/managed/content/group/uid/${uid}/management`,
        null,
        'DELETE'
      ),
      {
        pending: textFromUid(`pending_message_deteted_content_group`),
        success: textFromUid(`success_message_deteted_content_group`),
        error: textFromUid(`error_message_deteted_content_group`),
      }
    );

    const contentsUpdated = contents.filter((p) => p.uid != uid);
    setContents([...contentsUpdated]);
  };

  const updatedContent = (uid) => {
    if (!uid || !connector) return;
    let redirectTo = `/managed/content/connector/uid/${connector.uid}/group/uid/${uid}`;

    setRedirectTo(redirectTo);
  };

  const onPreviousButtonClick = () => {
    setCurrentPage(Math.max(1, currentPage - 1));
  };

  const onNextButtonClick = () => {
    setCurrentPage(Math.min(totalPages, currentPage + 1));
  };

  const handlePaginationClick = (page) => {
    setCurrentPage(Math.max(0, Math.min(totalPages, page)));
  };

  const filterOptions = useMemo(() => {
    return (
      <Filter
        onChange={(filterIds) => setFilters(filterIds)}
        onSearch={(text) => setSearch(text)}
      />
    );
  }, []);

  const usersFields = useMemo(() => {
    if (!contents) return <Loading msg={textFromUid('loading_message')} />;

    const orderFields = [
      'thumb_url',
      'uid',
      'name',
      'created_at',
      'updated_at',
    ];

    const paths = objectPaths(contents[0], {
      order: orderFields,
      namePrefix: 'content_group_field_',
      handleType: (key) => {
        if (key != 'thumb_url') return null;
        return 'image';
      },
    });

    return (
      <>
        {filterOptions}

        {processing && <Loading msg={textFromUid('loading_message')} />}

        {contents == 0 ? (
          <MessageNoItemInTable message="table_user_no_item" />
        ) : (
          <TableDynamic
            headers={paths}
            defaultFields={[]}
            data={contents}
            hasCheckbox={false}
            hasView={false}
            hasEdit={true}
            hasDelete={true}
            onDelete={deleteContent}
            onUpdate={updatedContent}
          />
        )}

        <Pagination
          currentPage={currentPage ? currentPage : 1}
          registerCount={totalPages}
          totalPages={totalPages}
          onPaginationButtonClick={(page) => handlePaginationClick(page)}
          onPreviousButtonClick={onPreviousButtonClick}
          onNextButtonClick={onNextButtonClick}
        />
      </>
    );
  }, [contents, processing, currentPage, totalPages]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <ContainerListUser>
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb
          currentRoute={consumer ? consumer.currentRoute : null}
          newOption={newContentGroup}
          customNewOptionTitle={textFromUid('button_new_content_group')}
          // importCsvEnabled={true}
          // importCsvOnClick={() => importCsvOnClick()}
        />

        <PageContainer>
          <Components.Headers.TitleAndDescription
            title={textFromUid('course_structure_title')}
            description={textFromUid('course_structure_description')}
            padding="20px 0px 20px 0px"
          />

          {usersFields}
        </PageContainer>
      </ContainerListUser>
    </>
  );
}
