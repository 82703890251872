import Sdk from 'api.digitalpages.module.sdk.api';
import { textFromUid } from '../utils/textUtilities';
import { forEach } from 'lodash';

const groupService = function () {
  try {
    this.addRoute = function (items, newRoute) {
      const route = items.find((r) => r.uri == newRoute.uri);
      if (route != undefined) return;

      items.push(newRoute);
    };

    this.getGroups = async function () {
      const _this = this;
      const connectors = Sdk.authorization.activeProject
        ? Sdk.authorization.activeProject.connectors
        : [];
      const result = [];
      if (connectors == null || connectors == undefined) {
        return result;
      }

      // const connectorsData = await Sdk.authorization.getUserConnectorData();
      const dashboards = await Sdk.dynamic.bridge(`insights/v1.1/report/authorized`,null,'GET');
      // const userToken = Sdk.authorization.credential.accessToken;

      var env = Sdk.enviroment == 'homolog' ? 'h' : 'p';
      const dashboardOptions = {
        name: textFromUid('menu_group_dashboards'),
        icon: 'icon-home',
        type: 'dashboards',
        subItems: [],
      };

      const appOptions = {
        name: textFromUid('menu_group_apps'),
        icon: 'icon-home',
        type: 'apps',
        subItems: [],
      };

      const configOptions = {
        name: textFromUid('menu_group_configs'),
        icon: 'icon-home',
        type: 'configs',
        subItems: [],
      };

      const learningOptions = {
        name: textFromUid('menu_group_learning'),
        icon: 'icon-home',
        type: 'learning',
        subItems: [],
      };

      const reviewOptions = {
        name: textFromUid('menu_group_review'),
        icon: 'icon-home',
        type: 'review',
        subItems: [],
      };

      const iotOptions = {
        name: textFromUid('menu_group_iot'),
        icon: 'icon-home',
        subItems: [],
      };

      const storeOptions = {
        name: textFromUid('menu_group_store'),
        icon: 'icon-home',
        subItems: [],
      };

      for(var dashboad of dashboards)
      {
        this.addRoute(dashboardOptions.subItems, {
          name: dashboad.name,
          uri: `/dashboard/uid/${dashboad.id}`,
          icon: 'icon-user',
        });
      }

      if (Sdk.hasRole(['AdministratorGlobal', 'ContributorGlobal']))
      { 
        this.addRoute(configOptions.subItems, {
          name: textFromUid('menu_option_configs_directory_users'),
          uri: '/directory/users',
          icon: 'icon-user',
        });
      }

      if (Sdk.hasRole(['AdministratorGlobal', 'UserAdmin', 'ContributorGlobal']))
      { 
        this.addRoute(configOptions.subItems, {
          name: textFromUid('menu_option_configs_project_users'),
          uri: '/project/users',
          icon: 'icon-user',
        });
      }

      if (Sdk.hasRole(['AdministratorGlobal', 'UserAdmin']))
        this.addRoute(configOptions.subItems, {
          name: textFromUid('menu_option_project_list'),
          uri: '/project',
          icon: 'icon-user',
        });

      if (
        Sdk.hasRole([
          'AdministratorGlobal',
          'ContributorGlobal',
          'SchemaAdmin',
          'SchemaContributor',
        ])
      )
        // this.addRoute(configOptions.subItems, {
        //   name: textFromUid('menu_option_configs_entities'),
        //   uri: '/schema',
        //   icon: 'icon-home',
        // });

        this.addRoute(configOptions.subItems, {
          name: textFromUid('menu_option_configs_group'),
          uri: '/groups',
          icon: 'icon-home',
        });

      connectors.map((connector, i) => {
        switch (connector.type) {
          case 'Iot_1': {
            this.addRoute(iotOptions.subItems, {
              name: textFromUid('menu_option_iot_devices'),
              uri: '/devices',
            });
            this.addRoute(iotOptions.subItems, {
              name: textFromUid('menu_option_iot_groups'),
              uri: '/iot/groups',
            });
            break;
          }
          case 'Payment_1': {
            if (Sdk.hasRole(['AdministratorGlobal']))
              this.addRoute(storeOptions.subItems, {
                name: textFromUid('menu_option_store_products'),
                uri: '/products',
                icon: 'icon-user',
              });
            if (Sdk.hasRole(['AdministratorGlobal']))
              this.addRoute(storeOptions.subItems, {
                name: textFromUid('menu_option_store_transactions'),
                uri: '/transactions',
                icon: 'icon-user',
              });
            break;
          }
          case 'Learning_1': {
            if (
              Sdk.hasRole([
                'AdministratorGlobal',
                'LearningAdmin',
                'LearningContributor',
              ])
            ) {
              this.addRoute(learningOptions.subItems, {
                name: textFromUid('menu_option_learning_courses'),
                uri: '/tree',
                icon: 'icon-user',
              });

              this.addRoute(learningOptions.subItems, {
                name: textFromUid('menu_option_learning_certificates'),
                uri: '/certificates',
                icon: 'icon-user',
              });

              var uriApp = `${Sdk.domain}/product/app/c/${Sdk.projectKey}/p/${Sdk.authorization.activeProject.uid}/${env}/`;
              if (window.location.origin.indexOf('manager') != -1) {
                uriApp = window.location.origin.replace('manager', 'app');

                //todo: remover código temporário após inserção do bundle
                if (uriApp.indexOf('ituring') != -1) {
                  uriApp = uriApp.replace('app.', 'classroom.');
                }
              }

              this.addRoute(appOptions.subItems, {
                name: textFromUid('menu_option_apps_app'),
                uri: uriApp,
                force_project_and_access_token: true,
              });
            }
            break;
          }
          case 'Review_1': {
            if (
              Sdk.hasRole([
                'AdministratorGlobal',
                'ReviewAdmin',
                'ReviewContributor',
              ])
            )
              this.addRoute(reviewOptions.subItems, {
                name: textFromUid('menu_option_review_flows'),
                uri: '/reviews',
                icon: 'icon-user',
              });
            if (
              Sdk.hasRole([
                'AdministratorGlobal',
                'ReviewAdmin',
                'ReviewContributor',
              ])
            )
              this.addRoute(reviewOptions.subItems, {
                name: textFromUid('menu_option_review_rubrics'),
                uri: '/rubric',
                icon: 'icon-user',
              });
            break;
          }
          case 'Customization_1': {
            if (Sdk.hasRole(['AdministratorGlobal']))
              this.addRoute(reviewOptions.subItems, {
                name: 'Configurações de layout',
                uri: '/settings/layout/edit',
                icon: 'icon-user',
              });
            break;
          }
          case 'Composer_1': {
            var uri = `${Sdk.domain}/product/composer/c/${Sdk.projectKey}/p/${Sdk.authorization.activeProject.uid}/${env}/`;
            this.addRoute(appOptions.subItems, {
              name: textFromUid('menu_option_apps_composer'),
              uri: '/embed/composer',
              externalUri: uri,
              force_project_and_access_token: true,
              embed: {
                hash: 'embed',
              },
            });
            break;
          }
          case 'Guide_1': {
            var uri = `${Sdk.domain}/product/guides/c/${Sdk.projectKey}/p/${Sdk.authorization.activeProject.uid}/${env}/`;
            if (window.location.origin.indexOf('manager') != -1)
              uri = window.location.origin.replace('manager', 'guides');

            this.addRoute(appOptions.subItems, {
              name: textFromUid('menu_option_apps_guides'),
              uri: '/embed/guides',
              externalUri: uri,
              force_project_and_access_token: true,
              embed: {
                hash: 'embed',
              },
            });
            break;
          }
          case 'Dam_1': {
            var uri = `${Sdk.domain}/product/dam/c/${Sdk.projectKey}/p/${Sdk.authorization.activeProject.uid}/${env}/`;
            this.addRoute(appOptions.subItems, {
              name: textFromUid('menu_option_apps_dam'),
              uri: '/embed/dam',
              externalUri: uri,
              force_project_and_access_token: true,
              embed: {
                hash: 'embedv2/files',
              },
            });
            break;
          }
          case 'DirectRegister_1': {
            if (Sdk.hasRole(['AdministratorGlobal', 'UserAdmin']))
              this.addRoute(configOptions.subItems, {
                name: textFromUid('menu_option_access_external'),
                uri: '/external',
                icon: 'icon-user',
              });

            break;
          }
        }
      });

      connectors.map((connector, i) => connector.features?.map(feature => {
          switch(feature)
          {
            case "IConnectorManagedContent":
              {
                if (Sdk.hasRole([
                  'AdministratorGlobal',
                  'ContributorGlobal',
                  'ManagedContentAdmin',
                  'ManagedContentContributor'
                ]))
                {
                  this.addRoute(learningOptions.subItems, {
                    name: uniqueName(textFromUid('menu_option_managed_content'), connector.internal_name),
                    uri: `/managed/content/connector/uid/${connector.uid}`,
                    icon: 'icon-user',
                  }); 
                }

                 
                break;
              }
          }
        })
      );

      if (dashboardOptions.subItems.length >0) result.push(dashboardOptions);
      if (appOptions.subItems.length > 0) result.push(appOptions);
      if (configOptions.subItems.length > 0) result.push(configOptions);
      if (learningOptions.subItems.length > 0) result.push(learningOptions);
      if (reviewOptions.subItems.length > 0) result.push(reviewOptions);
      if (storeOptions.subItems.length > 0) result.push(storeOptions);
      if (iotOptions.subItems.length > 0) result.push(iotOptions);

      // const response = await fetch('./preferences.json');
      // const preferences = await response.json();
      // result.push(...preferences.sidebar.groups);

      return result;
    };
  } catch (error) {}
};

const uniqueName = (name, subname) => {
  if (!subname) return name;
  var result = `${name} (${subname})`;
  return result;
};

const GroupService = new groupService();

export { GroupService };
