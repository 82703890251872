import React, { Children, Component } from 'react';
import './page-container.scss';

export default function PageContainer ({children, full}) 
{
    var classname = "page-container-content";

    if (full) classname += " full";

    return (
      // <div className="rdp-admin-content rdp-admin-component-form-dynamic">
      //   <div className="form-main-content all-page">
      //     <div className="content-fields">
      //       {children}
      //     </div>
      //   </div>
      // </div>

      <div className="page-container-root page-container-master">
        {/* <div className="page-container-content page-container-all"> */}
          <div className={classname} >
            {children}
          </div>
        {/* </div> */}
      </div>
    );
}
