import React, { Component } from 'react';
import './user-management.scss';
import Sdk from 'api.digitalpages.module.sdk.api';
import { IconCloseModal } from '../../components/icon';
import Modal from 'react-responsive-modal';
import {
  Header,
  Sidebar,
  Breadcrumb,
  FormDynamic,
  NotifyBox,
} from '../../components';

import Loading from '../../components/loading';
import Configuration from './user-management.config';

export default class ViewUserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: {
        nodes: [],
      },
      selectOptions: [],
      entity: Configuration.getEntity(),
      ready: false,
      loading: false,
      loadingSubmit: false,
      msgSuccess: null,
      msgError: null,
    };
  }

  componentDidMount() {
    const { courseUid } = this.props.match.params;
    if (courseUid) {
      this.setState({ ready: false, loading: true }, this.getUserDetails);
    }
  }

  getMembers = async (current) => {
    if (!current._exists) return;
    if (this.state.configurationValues._old.members[current._uid]) return;

    const uid = current._uid;

    const members = await Sdk.Api.authorization.entity.members(uid);

    this.setState({
      configurationValues: {
        ...this.state.configurationValues,
        _old: {
          ...this.state.configurationValues._old,
          members: {
            ...this.state.configurationValues._old.members,
            [uid]: members.map((member) => ({
              user: member.uid,
              role: member.roles.map((role) => role.type),
            })),
          },
        },
      },
    });
  };

  getUserDetails = async () => {
    const { courseUid } = this.props.match.params;

    const types = {
      Text: 'input-text',
    };

    const course = await Sdk.Api.cms.course(courseUid);
    const props = course.node_properties.map((prop) => ({
      identifier: prop.name,
      field: prop.name,
      label: prop.name,
      type: types[prop.type],
    }));

    const { entity } = this.state;

    this.setState({
      schemaProps: props,
      entity,
    });

    // if (courseUid != "new") {
    //   const nodes = await Sdk.Api.authorization.entity.schemaItems(courseUid);

    //   this.setState({
    //     values: {
    //       ...this.state.values,
    //       nodes: this.rehydratenodes(nodes, props)
    //     }
    //   });
    // }

    this.setState({
      ready: true,
      loading: false,
    });
  };

  saveMembers = async (uid) => {
    const newMembers = this.state.configurationValues._new.members[uid] || [];
    const oldMembers = this.state.configurationValues._old.members[uid] || [];

    const registerRoleMembers = async (member) => {
      if (!member.user) return;

      await Sdk.Api.authorization.entity.registerMember(uid, member.user, {
        role: member.role.join(','),
      });
    };

    const deleteRoleMembers = async () => {
      return oldMembers
        .filter(
          (member) =>
            !newMembers.map((member) => member.user).includes(member.user)
        )
        .map(
          async (member) =>
            await Sdk.Api.authorization.entity.removeMember(uid, member.user)
        );
    };

    const tasksCreate = newMembers.map(registerRoleMembers);
    const tasksDelete = newMembers.map(deleteRoleMembers);

    await Promise.all(tasksCreate);
    await Promise.all(tasksDelete);
  };

  saveEntity = async (entity) => {
    const { courseUid } = this.props.match.params;

    if (entity._removed && entity._exists) {
      await Sdk.Api.authorization.entity.deleteSchemaItem(entity._uid, entity);
      return;
    }

    if (entity._parent) {
      const parent = this.state.values.nodes.find(
        (_entity) => _entity._uid == entity._parent
      );
      if (!parent._exists) {
        entity._parent = this.saveEntity(parent);
      }
    }

    let stored = {};

    if (entity._exists) {
      stored = await Sdk.Api.authorization.entity.updateSchemaItem(
        entity._uid,
        entity
      );
    } else {
      stored = await Sdk.Api.authorization.entity.createSchemaItem(
        courseUid,
        entity
      );
    }

    this.setState(
      {
        configurationValues: {
          ...this.state.configurationValues,
          _old: {
            ...this.state.configurationValues._old,
            members: {
              ...this.state.configurationValues._old.members,
              [stored.uid]: this.state.configurationValues._old.members[
                entity._uid
              ],
            },
          },
          _new: {
            ...this.state.configurationValues._new,
            members: {
              ...this.state.configurationValues._new.members,
              [stored.uid]: this.state.configurationValues._new.members[
                entity._uid
              ],
            },
          },
        },
        values: {
          ...this.state.values,
          nodes: this.state.values.nodes.map((_entity) => {
            if (_entity._uid == entity._uid) {
              _entity._exists = true;
              _entity._uid = stored.uid;
            }
            if (_entity._parent == entity._uid) {
              _entity._parent = stored.uid;
            }
            return _entity;
          }),
        },
      },
      () => {
        this.saveMembers(stored.uid);
      }
    );

    return stored.uid;
  };

  saveAll = async () => {
    const nodes = this.hydratenodes(
      this.state.values.nodes.filter((entity) => !entity._empty)
    );

    try {
      const saved = nodes.map(this.saveEntity);

      await Promise.all(saved);

      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgSuccess: 'Salvo com sucesso!',
      });
    } catch (e) {
      this.setState({
        ready: true,
        loading: false,
        loadingSubmit: false,
        msgError: 'Erro ao salvar.',
      });
    }
  };

  handleSubmit = (form) => {
    this.setState(
      {
        ready: true,
        loading: false,
        loadingSubmit: true,
      },
      () => this.saveAll(form.data)
    );
  };

  resetMsg = () => {
    this.setState({ msgSuccess: null, msgError: null });
  };

  handleModalSubmit = (form, current) => {
    this.setState(
      {
        configurationValues: {
          ...this.state.configurationValues,
          _new: {
            ...this.state.configurationValues._new,
            members: {
              ...this.state.configurationValues._new.members,
              [current._uid]: form.data.members.map((member) => ({
                user: member.user,
                role: member.role,
              })),
            },
          },
        },
        open: false,
      },
      () => {
        console.log(
          'this.state.configurationValues._new',
          this.state.configurationValues._new
        );
      }
    );
  };

  handleOnDetails = async (current) => {
    await this.getMembers(current);
    this.setState({
      open: {
        current,
      },
    });
  };

  render() {
    const { consumer } = this.props;
    const {
      values,
      entity,
      loading,
      loadingSubmit,
      msgSuccess,
      msgError,
    } = this.state;

    return (
      <div className="rdp-admin-new-entity">
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />
        <div id="rdp-admin-content-area" className="rdp-admin-content">
          {loading && <Loading msg="Carregando formulário..." />}
          {loadingSubmit && <Loading msg="Saving..." />}
          {!loadingSubmit && msgSuccess && (
            <NotifyBox
              type="success"
              onClose={this.resetMsg}
              message={msgSuccess}
            />
          )}
          {!loadingSubmit && msgError && (
            <NotifyBox
              type="error"
              onClose={this.resetMsg}
              message={msgError}
            />
          )}
          {!loading && entity && (
            <FormDynamic
              actions={{
                nodes: { onDetails: this.handleOnDetails },
              }}
              selectOptions={this.state.selectOptions}
              values={values}
              blocked={msgSuccess || msgError ? true : false}
              isNew={true}
              cleanValues={msgSuccess ? true : false}
              handleSubmit={this.handleSubmit}
              entity={entity}
              selectOptions={this.state.selectOptions}
              hideDraftButton={true}
              submitText={'Salvar'}
              submitEnabled={true}
            />
          )}
        </div>
      </div>
    );
  }
}
