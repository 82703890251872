import React, { useState, useEffect, useMemo, useRef } from 'react';
import DynamicForm from '../../components/form-dynamic-v2';
import {
  Sidebar,
  Breadcrumb,
  Header,
  Footer,
  TableDynamic,
  MessageNoItemInTable,
  Pagination,
  Filter,
  PageContainer,
  LoadingFullscreen,
} from '../../components';
import Components from 'rdp-builder-components';
import Loading from '../../components/loading';
import { toast } from 'react-toastify';
import { textFromUid } from '../../utils/textUtilities';
import {
  objectPaths,
  objectToForm,
  dynamicPropertiesToForm,
  formToDynamicProperties,
} from '../../utils/formUtilities';

import { Redirect } from 'react-router-dom';
import Sdk from 'api.digitalpages.module.sdk.api';

import { ContainerListUser } from './style';

export default function ManagedContentNew({ consumer, match, history }) {
  const [content, setContent] = useState(null);
  const [redirectTo, setRedirectTo] = useState(null);

  useEffect(() => {
    consumer.defineRoute({
      name: textFromUid('screen_managed_content_new'),
    });

    setContent({
      name: '',
      description: '',
    });
  }, []);

  const handleRegisterContent = async (data) => {
    const connectorUid = match.params.connectorUid;
    const connector = Sdk.Api.authorization.activeProject.connectors.find(
      (p) => p.uid == connectorUid
    );
    const properties = JSON.parse(connector.configuration).properties;
    const result = formToDynamicProperties(data, properties);

    //todo: temporário
    var postData = {
      name: data.name,
      description: data.description,
      properties: result,
    };

    const group = await toast.promise(
      Sdk.dynamic.bridge(
        `cms/v1.1/managed/content/connector/uid/${connectorUid}/management`,
        postData,
        'POST'
      ),
      {
        pending: textFromUid(`pending_message_create_new_content`),
        success: textFromUid(`success_message_create_new_content`),
        error: textFromUid(`error_message_create_new_content`),
      }
    );

    const redirectTo = `/managed/content/connector/uid/${connectorUid}/group/uid/${group.uid}`;
    setRedirectTo(redirectTo);
  };

  const contentOptions = useMemo(() => {
    if (!content) return <Loading msg={textFromUid('loading_message')} />;

    const connectorUid = match.params.connectorUid;
    const connector = Sdk.Api.authorization.activeProject.connectors.find(
      (p) => p.uid == connectorUid
    );
    const properties = JSON.parse(connector.configuration).properties;


    const baseOptions = objectToForm({ name: "-", description: "-" });
    const options = dynamicPropertiesToForm(properties);
    options.fields = [
        ...baseOptions,
        ...options.fields
    ]

    console.log(options);
    return (
      <DynamicForm
        handleSubmit={handleRegisterContent}
        form={options}
        submitText={textFromUid('register_managed_content')}
        defaultValues={{}}
      />
    );
  }, [content]);

  if (redirectTo) {
    return <Redirect to={redirectTo} />;
  }

  return (
    <>
      <ContainerListUser>
        <Header />
        <Sidebar
          defineGroup={consumer ? consumer.defineGroup : null}
          defineRoute={consumer ? consumer.defineRoute : null}
          groups={consumer ? consumer.groups : null}
        />
        <Breadcrumb currentRoute={consumer ? consumer.currentRoute : null} />

        <PageContainer>
          <Components.Headers.TitleAndDescription
            title={textFromUid('course_structure_title')}
            description={textFromUid('course_structure_description')}
            padding="20px 0px 20px 0px"
          />
          {contentOptions}
        </PageContainer>
      </ContainerListUser>
    </>
  );
}
