import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { format } from 'date-fns';
import './table-dynamic.scss';

import {
  IconEdit,
  IconTrash,
  IconClone,
  IconPublished,
  IconDraft,
  IconScheduled,
  IconSubscriber,
  IconNonSubscriber,
  IconCircleNone,
  IconOk,
  ViewListIcon,
} from '../icon';
import ModalDelete from '../modal-delete';

const fieldsType = {
  INPUT_TEXT: 'input-text',
  INPUT_NUMBER: 'input-number',
  INPUT_COLOR: 'input-color',
  TEXT: 'text',
  BOOLEAN: 'boolean',
  ICON_TEXT: 'icon-text',
  IMAGE: 'image',
  NUMBER: 'number',
  DATE: 'date',
  DATE_TIME: 'date-time',
  STATUS: 'status',
  SELECT: 'select',
  MULTIPLE_SELECT: 'multiple-select',
  REFERENCE_ENTITY: 'reference-entity',
  CONNECTOR: 'connector',
};

const statusType = {
  PUBLISHED: 'Published',
  DRAFT: 'Draft',
  SCHEDULED: 'Scheduled',
  FINISHED: 'Finished',
  OK: 'OK',
  NOONE: 'NOONE',
};

export default class TableTrDynamic extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpenDelete: false,
      modalOpenClone: false,
    };
  }

  openModal() {
    this.setState({ modalOpenDelete: true });
  }

  closeModal() {
    this.setState({ modalOpenDelete: false });
  }

  openModalClone() {
    this.setState({ modalOpenClone: true });
  }

  closeModalClone() {
    this.setState({ modalOpenClone: false });
  }

  deleteAction = () => {
    const { onDelete, tr } = this.props;
    onDelete(tr.uid);
    this.closeModal();
  };

  cloneAction = () => {
    const { onClone, tr } = this.props;
    onClone(tr.uid);
    this.closeModalClone();
  };

  getDeleteButton(id, title) {
    return (
      <>
        <Link to="#" onClick={() => this.openModal()}>
          <IconTrash />
        </Link>
        <ModalDelete
          open={this.state.modalOpenDelete}
          action={this.deleteAction}
          title={title}
          onClose={() => this.closeModal()}
        />
      </>
    );
  }

  getCloneButton(id, title) {
    return (
      <>
        <Link to="#" onClick={() => this.openModalClone()}>
          <IconClone />
        </Link>
        <ModalDelete
          open={this.state.modalOpenClone}
          action={this.cloneAction}
          color={'blue'}
          title="Clonar"
          onClose={() => this.closeModalClone()}
          // Icon={<IconClone />}
          message="Tem certeza que deseja clonar esse curso?"
          icon={<IconClone />}
          textAction="clonar"
        />
      </>
    );
  }

  getViewListButton(id, title) {
    // console.log({ id });
    return (
      <Link to={`/schema/${id}`}>
        <ViewListIcon />
      </Link>
    );
  }

  getIcon({ type, text }) {
    if (type === 'subscriber') {
      if (text === 'Sim') {
        return <IconSubscriber />;
      }

      return <IconNonSubscriber />;
    } else if (type === 'status') {
      if (text === 'Publicado') {
        return <IconPublished />;
      } else if (text === 'Agendado') {
        return <IconScheduled />;
      } else if (text === 'Draft') {
        return <IconDraft />;
      }
    }

    return undefined;
  }

  getCheckBox(td, onCheckboxClick, defaultFields) {
    return (
      <td key={td.text} className="text-center">
        <input
          className="td-checkbox"
          type="checkbox"
          onClick={() => onCheckboxClick('1')}
          defaultChecked={defaultFields.includes(td.uid)}
        />
      </td>
    );
  }

  getStatusIcon(status) {
    if (status === 'Published') {
      return <IconPublished />;
    } else if (status === 'Scheduled') {
      return <IconScheduled />;
    } else if (status === 'Draft') {
      return <IconDraft />;
    } else if (status.toUpperCase() === 'OK') {
      return <IconOk />;
    } else if (status.toUpperCase() === 'NOONE') {
      return <IconCircleNone />;
    }

    return <IconPublished />;
  }

  renderStatusField(data) {
    return (
      <td>
        <div className="column-content">
          <div className="td-icon">{this.getStatusIcon(data)}</div>
          <span className="td-text">{this.getStatusTranslated(data)}</span>
        </div>
      </td>
    );
  }

  renderTextField(value) {
    return (
      <td>
        <div className="column-content">{value ? value : ' - '}</div>
      </td>
    );
  }

  transformingBooleanText(value) {
    if (value === true) {
      return ' Sim';
    } else {
      return 'Não';
    }
  }

  renderBoolean(value) {
    return (
      <td>
        <div className="column-content">
          {this.transformingBooleanText(value)
            ? this.transformingBooleanText(value)
            : ' - '}
        </div>
      </td>
    );
  }
  renderImageField(value) {
    return (
      <td>
        <div className="column-content">
          <img className="table-icon" src={value} />
        </div>
      </td>
    );
  }

  renderIconTextField(icon, value) {
    return (
      <td>
        <div className="column-content">
          {icon && value ? (
            <>
              <img className="table-icon" src={icon} /> {value}
            </>
          ) : (
            ` - `
          )}
        </div>
      </td>
    );
  }

  renderNumberField(value) {
    return (
      <td>
        <div className="column-content">{value ? value : '-'}</div>
      </td>
    );
  }

  renderDateField(value) {
    return (
      <td>
        <div className="column-content">
          {value ? format(new Date(value), 'dd/MM/yyyy') : '-'}
        </div>
      </td>
    );
  }

  renderDateTimeField(value) {
    const result = new Date(value);
    // const result = date.toDatetimeLocal();
    var resultData = result;

    try {
      if (result) {
        resultData = format(
          result,
          // "dd/MM/yyyy 'às' HH:mm:ss"
          "dd/MM/yyyy '-' HH:mm"
        );
      }
    } catch (e) {}

    return (
      <td>
        <div className="column-content">{resultData}</div>
      </td>
    );
  }

  getStatusTranslated(status) {
    if (statusType.PUBLISHED === status) {
      return 'Publicado';
    } else if (statusType.DRAFT === status) {
      return 'Rascunho';
    } else if (statusType.SCHEDULED === status) {
      return 'Agendado';
    } else if (statusType.FINISHED === status) {
      return 'Finalizado';
    } else if (statusType.OK === status.toUpperCase()) {
      return 'OK';
    } else if (statusType.NOONE === status.toUpperCase()) {
      return 'Ninguém';
    }
  }

  renderMultiSelectField(value) {
    return (
      <td>
        <div className="column-content">
          {value && value.map((item) => item.label).join(',')}
        </div>
      </td>
    );
  }

  renderColorField = (value) => {
    return (
      <td className="column-reference-entity">
        <div className="column-content--reference">
          <div
            style={{ backgroundColor: value }}
            className="column-color"
          ></div>
        </div>
      </td>
    );
  };

  renderReferenceEntityField = (field, value) => {
    const { reference_entity_properties } = field;

    const child = reference_entity_properties.options_fields.map((option) => {
      if (!value) return <span></span>;
      if (!value.data) return <span></span>;

      if (option.type == fieldsType.INPUT_COLOR) {
        return (
          <div
            style={{ backgroundColor: value.data[option.identifier] }}
            className="column-color"
          ></div>
        );
      }

      if (option.type == fieldsType.INPUT_TEXT) {
        return <span>{value.data[option.identifier]}</span>;
      }

      return null;
    });

    return (
      <td className="column-reference-entity">
        <div className="column-content--reference">{child}</div>
      </td>
    );
  };

  renderField = (field, row) => {
    if (field?.path) {
      const data = eval(`row.${field?.path}`);
      return this.currentComponent(field, data, row);
    }

    return this.currentComponent(field, row[field?.identifier], row);
  };

  currentComponent = (field, data, row) => {
    switch (field?.type) {
      case fieldsType.INPUT_TEXT:
      case fieldsType.TEXT: {
        if (data?.length > 100) {
          data = `${data.substr(0, 100)}...`;
        }

        return this.renderTextField(data);
      }
      case fieldsType.ICON_TEXT: {
        const icon = eval(`row.${field?.icon_path}`);

        if (data?.length > 100) {
          data = `${data.substr(0, 100)}...`;
        }

        return this.renderIconTextField(icon, data);
      }
      case fieldsType.INPUT_NUMBER: {
        return this.renderTextField(data);
      }
      case fieldsType.BOOLEAN: {
        return this.renderBoolean(data);
      }
      case fieldsType.STATUS: {
        return this.renderStatusField(data);
      }
      case fieldsType.IMAGE: {
        return this.renderImageField(data);
      }
      case fieldsType.NUMBER: {
        return this.renderNumberField(data);
      }
      case fieldsType.DATE: {
        return this.renderDateField(data);
      }
      case fieldsType.DATE_TIME: {
        return this.renderDateTimeField(data);
      }
      case fieldsType.SELECT: {
        return this.renderTextField(data);
      }
      case fieldsType.MULTIPLE_SELECT: {
        return this.renderMultiSelectField(data);
      }

      case fieldsType.REFERENCE_ENTITY: {
        return this.renderReferenceEntityField(field, data);
      }

      case fieldsType.INPUT_COLOR: {
        return this.renderColorField(data);
      }
      case fieldsType.CONNECTOR: {
        data = data.replace('_', ' v');
        return this.renderTextField(data);
      }

      default: {
        return <></>;
      }
    }
  };

  render() {
    const {
      onUpdate,
      onClone,
      handleView,
      hasCheckbox,
      hasDelete,
      hasEdit,
      hasClone,
      hasViewList,
      hasView,
      tr,
      title,
      onCheckboxClick,
      headers,
      defaultFields,
    } = this.props;

    const fields = [...headers, ...defaultFields];

    return (
      <tr data-testid="tr-table-dynamic" key={tr.uid}>
        {hasCheckbox
          ? this.getCheckBox(tr, () => onCheckboxClick(tr.uid), defaultFields)
          : null}
        {fields.map((field, i) => (
          <React.Fragment key={i}>{this.renderField(field, tr)}</React.Fragment>
        ))}
        {hasEdit && (
          <td className="icon">
            <Link to="#" onClick={() => onUpdate && onUpdate(tr.uid)}>
              <IconEdit />
            </Link>
          </td>
        )}
        {hasView && (
          <td className="icon">
            <Link to="#" onClick={() => handleView && handleView(tr.uid)}>
              <ViewListIcon />
            </Link>
          </td>
        )}
        {hasViewList && (
          <td className="icon">{this.getViewListButton(tr.uid, title)}</td>
        )}
        {hasClone && <td className="icon">{this.getCloneButton(title)}</td>}
        {hasDelete && <td className="icon">{this.getDeleteButton(title)}</td>}
      </tr>
    );
  }
}
