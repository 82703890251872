import React, { useEffect, useMemo, useState } from 'react';
import './login.scss';
import Sdk from 'api.digitalpages.module.sdk.api';
import defaultBackground from '../../assets/images/banner-instituto.png';

import Loading from '../../components/loading';
import RDP_CONFIG from '../../config/config';
import Components from 'rdp-builder-components';
import { LoginContainer, LoginMessage } from './style';
import Logo from "../../assets/images/logo_instituto-jef.jpg"
import { textFromUid } from '../../utils/textUtilities';

export default function ViewLogin({ consumer, match, history }) 
{
  const [ loading, setLoading ] = useState(false);
  const [ language, setLanguage ] = useState( window.localStorage.getItem('rdp-manager-current-language'));
  const [ version, ] = useState(process.env.REACT_APP_VERSION ? `v${process.env.REACT_APP_VERSION}` : undefined);

  const [ login, setLogin ] = useState(null);
  const [ password, setPassword ] = useState(null);
  const [ projectTimeout, setProjectTimeout ] = useState(null);
  const [ projects, setProjects ] = useState([]);
  const [ activeProject, setActiveProject ] = useState(null);
  const [ systemMessage, setSystemMessage ] = useState(null);

  useEffect(()=>
  {
    window.localStorage.setItem('rdp-manager-current-language', language);
  },[language]);

  useEffect(()=>
  {
    if (login?.length > 3)
    {
      setProjects([]);
      setSystemMessage(null);

      clearTimeout(projectTimeout);
      var timeoutId = setTimeout(()=> { loadProjects(); },  1000);
      setProjectTimeout(timeoutId);
    }
    if (activeProject) setActiveProject(null);
  }, [login]);

  useEffect(()=>
  {
    if (projects.length != 1) return;
    setActiveProject(projects[0]);
  }, [projects])

  const loadProjects = async (currentLogin)=>
  {
    setSystemMessage(textFromUid('login_finding_user'));

    const url = `auth/v1.0/directory/user/login/projects/available?login=${encodeURIComponent(login)}&ignore_user_context=true`;
    const projects = await Sdk.Api.dynamic.bridge(url, null, "GET");
    const result = projects.map(p=> 
    {
        var data = { uid : p.uid, name : p.name };
        return data;
    });

    if (result.length == 0)
    {
      setSystemMessage(textFromUid('login_user_not_found'));
    }else{
      setSystemMessage(null);
    }

    setProjects(result);
  }

  const delay = async (time)=>
  {
    return new Promise(function(resolve)
    {
      setTimeout(resolve, time);
    });
  }

  const startLogin = async ()=>
  {
    try
    {
      setLoading(true);
      setSystemMessage(textFromUid("login_processing"));
      await Sdk.Api.authorization.signinByProject(activeProject.uid, login, password);
    }finally
    {
      setLoading(false);

      if (!Sdk.Api.authorization.authenticated)
      {
        setSystemMessage(textFromUid('login_error'));
      }
    }   
  }

  const redirectToHome = ()=>
  {
    console.log(Sdk.Api.authorization.authenticated);
    if (Sdk.Api && Sdk.Api.authorization.authenticated) {
      window.location = `${
        window.location.origin + window.location.pathname
      }#/`;
    }
  }

  const getPreloader = useMemo(()=> 
  {
    return loading? (
      <div className="rdp-analytics-login-sidebar-preloader">
        <Loading msg="verificando credenciais..." />
      </div>
    ) : (
      ''
    );
  }, [loading]);

  const onSelectLanguage = (language)=>
  {
    setLanguage(language);
  }

  const renderLanguages =  useMemo(()=>
  {
    if (language) return null;

    return (
      <div className="rdp-composer-login-sidebar-languages">
        <div className="rdp-composer-login-sidebar-languages-overlay">
          <ul className="rdp-composer-login-sidebar-languages-items">
            <li
              className="rdp-composer-login-sidebar-languages-item"
              onClick={() => onSelectLanguage('en_us')}
            >
              <span className="rdp-composer-login-sidebar-languages-item-label">
                English
              </span>
              <span className="rdp-composer-login-sidebar-languages-item-icon en"></span>
            </li>

            <li
              className="rdp-composer-login-sidebar-languages-item"
              onClick={() => onSelectLanguage('pt_br')}
            >
              <span className="rdp-composer-login-sidebar-languages-item-label">
                Português Brasil
              </span>
              <span className="rdp-composer-login-sidebar-languages-item-icon pt_br"></span>
            </li>
          </ul>
        </div>
      </div>
    );
  }, [language]);

  let background = `url(${defaultBackground})`;

  if (RDP_CONFIG.customLoginBackground != null) {
    background = `url(${RDP_CONFIG.customLoginBackground})`;
  }

  const styleInf = { backgroundImage: background };

  return (
    <div className="rdp-analytics-login" style={styleInf}>
      <div className="rdp-analytics-login-sidebar">
        <div className="container-logo"> 
          {/* <div className="rdp-analytics-login-sidebar-logo" /> */}
          <img src={Logo} />
        </div>
        <div className="rdp-analytics-login-sidebar-form">
          <span className="rdp-analytics-login-title">
            {textFromUid('login_message_welcome')}
          </span>

          <LoginContainer>

            <Components.Forms.InputText
              key={`input-login`}
              label={textFromUid('login_email')}
              type={'text'}
              border="1px solid #d2d5dd"
              height="44px"
              fontColor="#000"
              onChange={(evt) => {
                var value = evt.target ? evt.target.value : '';
                setLogin(value);
                // setInputs((old) => ({ ...old, [field.id]: value }));
              }}
            />

            {projects?.length > 1 && <Components.Buttons.Select
              borderColor="#d3d5dd"
              size ="medium"
              title={"Projecto"}
              visibleOptions={6}
              disabled={true}
              defaultValue={activeProject?.uid}
              items={projects.map((option) => ({
                value: option.uid,
                label: option.name,
              }))}
              onChange={(evt) => {
                var value = evt ? evt : '';
                var project = projects.find(p=> p.uid == value);
                setActiveProject(project);
              }}
            />}

            {activeProject != null && <Components.Forms.InputText
              key={`input-password`}
              label={textFromUid('login_password')}
              type={'password'}
              border="1px solid #d2d5dd"
              height="44px"
              fontColor="#000"
              onChange={(evt) => {
                var value = evt.target ? evt.target.value : '';
                setSystemMessage(null);
                setPassword(value);
              }}
            />}

            {systemMessage && 
            <LoginMessage>
              {systemMessage}
            </LoginMessage>}

            <Components.Buttons.Button
              width="100%"
              height="44px"
              text="ENTRAR"
              disabled={!(activeProject && login && password)}
              onClick={startLogin}
            />

          </LoginContainer>

          <div className="rdp-analytics-footer">{version}</div>
        </div>
        {renderLanguages}
        {getPreloader}
      </div>
    </div>
  );
}