const textsEN = {
  true : "True",
  false : "False",
  add_node: 'Add node',
  add_entity: 'Add subgroup',
  name : "Name",
  description : "Description",
  blocked_access: 'blocked_access',

  button_login_as: 'Impersonate',
  button_new_user: 'New user',
  button_new_project: 'New Project',
  button_update_project: 'Update Project',
  button_new_tree: 'New course',
  button_new_schema_directory: 'New schema per directory',
  button_new_schema: 'Create group',
  button_new_register: 'New offer',
  button_update_roles: 'Change permissions',
  button_update_password: 'Change password',
  button_update_register_entities: 'Update permissions',
  button_update_register_details: 'Update information',
  button_insert_register_details: 'Save information',
  button_insert_transaction: 'Add transaction',
  button_insert_revision: 'Create review',
  button_new_import_curse: 'Import course',
  button_new_product: 'Create Product',
  button_new_transaction: 'Create fake purchase',
  button_remove_members: 'Remove Members',
  button_add_new_member: 'Adding new member',
  button_save_members: 'Add Members',
  button_new_certificate: 'Create certificate',
  button_new_access_external: 'New access',
  button_project_new: 'Create project',
  button_insert_content_managed_autorization: 'Create authorization',

  breadcrumb_import_users: 'Import users(csv)',
  breadcrumb_create: 'Create',
  breadcrumb_entity: 'Entity',
  breadcrumb_users: 'Users',
  breadcrumb_back: 'Back',
  breadcrumb_edition: '(Edition)',

  course_uid: 'uid',
  course_title: 'Course title',
  course_description: 'Description',
  course_internal_name: 'Internal title',
  course_created_at: 'Created in ',
  course_updated_at: 'Updated on',
  course_type: 'Type',
  course_type_formula_score: 'Formula score type',
  course_categories_description: 'Overall course category',
  course_categories_title: 'Category',
  course_field_created_at: 'Created on',
  course_field_internal_name: 'Name',
  course_field_tags: 'course_field_tags',
  course_field_title: 'Title',
  course_field_uid: 'UID',
  course_field_updated_at: 'Updated on',
  course_field_thumb_url: 'Cover',
  course_field_type: 'Type',
  course_field_type_formula_score: 'Formula score type',
  course_list_description: 'Table of registered courses',
  course_list_title: 'List of courses',
  course_registers_description:
    'Offers record, availability, visibility and interaction table',
  course_registers_title: 'Offers',
  course_structure_description: 'The following list represents the contents registered on the platform.',
  course_structure_title: 'Content',
  course_thumb: 'Thumb',
  couse_detail_description: 'Learning unit identification details',
  couse_detail_title: 'Course Information',

  user_detail_cpf: 'CPF',
  user_detail_email_main: 'Email',
  user_detail_email_principal: 'E-mail',
  user_detail_first_name: 'Name',
  user_detail_last_name: 'Surname',
  user_detail_name: 'As it should be called',
  user_password: 'Password',

  header_actived_project: 'Project',
  header_name_user: 'User',
  header_translation: 'Language',
  header_translation_pt: 'Portuguese',
  header_translation_en: 'English',

  information___internal: 'information___internal',
  information_blocked_access: `When blocked, the platform does not authorize the user's login`,
  information_connectors_data: 'information_connectors_data',
  information_created_at: ' ',
  information_updated_at: ' ',
  information_description: ' ',
  information_detail: 'information_detail',
  information_detail_cpf: ' ',
  information_detail_email_main: ' ',
  information_detail_first_name: ' ',
  information_detail_last_name: ' ',
  information_internal_name: ' ',
  information_login: ' ',
  information_user_password: ' ',
  information_pending_confirm: 'information_pending_confirm',
  information_tags: 'information_tags',
  information_title: ' ',
  information_uid: ' ',
  information_user_blocked_access: 'information_user_blocked_access',
  information_user_connectors_data: 'information_user_connectors_data',
  information_user_created_at: 'User registration date on the platform',
  information_user_detail: 'information_user_detail',
  information_user_detail_cpf: ' ',
  information_user_detail_email_main: 'Email used to communicate with the user',
  information_user_detail_first_name: ' ',
  information_user_detail_last_name: ' ',
  information_user_login: ' ',
  information_user_pending_confirm:
    'When pending, the platform may not authorize user login',
  information_user_uid: 'Unique user ID on platform',
  information_user_updated_at: 'Last change recorded on user',
  information_user_detail_name: 'Name used to communicate with the user',
  information_offer_name: 'Offer identification name',
  information_offer_register_start: 'As of when the offer will be available',
  information_offer_register_end: 'Until when will the offer be available',
  information_offer_interaction_start:
    'From when interactions should be recorded',
  information_offer_interaction_end:
    'Until when user interactions will be logged',
  information_offer_visibility_start:
    'From when will the offer be visible to users',
  information_offer_visibility_end:
    'Until when will the offer be visible to users',
  information_node_uid: 'Unit identification code',
  information_node_created_at: 'Unit created date',
  information_node_updated_at: 'Date the unit was last updated',
  information_node_data_title: 'Learning unit title',
  information_node_duration: 'Unit duration',
  information_entity_uid: ' ',
  information_entity_row: ' ',
  information_entity_column: ' ',
  information_entity_data_name: ' ',
  information_entity_roles: ' ',
  information_entity_created_at: ' ',
  information_entity_updated_at: ' ',
  information_course_uid: ' ',
  information_course_title: ' ',
  information_course_internal_name: ' ',
  information_course_created_at: ' ',
  information_course_updated_at: ' ',
  information_course_type: ' ',
  information_course_type_formula_score: ' ',

  entity_structure_title: 'Group hierarchy',
  entity_structure_description: 'Structure of entity units',

  member_structure_title: 'Members',
  member_structure_description: 'Add new members',

  loading_message: 'Loading...',

  login: 'User',
  login_message_welcome: 'Login to RDP Manager with your credentials.',
  login_email: 'Email or user',
  login_password: 'password',
  login_button: 'log in',
  login_message_error_credentials:
    'Invalid credentials, please check and try again.',
  login_message_error_fields_credentials:
    'Fill in the fields with your credentials.',

  menu_group_apps: 'Applications',
  menu_group_configs: 'Settings',
  menu_group_iot: 'IoT',
  menu_group_learning: 'Learning',
  menu_group_review: 'Reviews',
  menu_group_store: 'Store',
  menu_group_dashboards: 'Dashboards',
  menu_option_apps_app: 'RDP App',
  menu_option_apps_composer: 'RDP Composer',
  menu_option_apps_guides: 'Guides',
  menu_option_apps_dam: 'RDP Dam',
  menu_option_configs_entities: 'Entities',
  menu_option_configs_group: 'Groups',
  menu_option_configs_directory_users: 'Users in directory',
  menu_option_configs_project_users: 'Users in project',
  menu_option_learning_certificates: 'Certificates',
  menu_option_learning_courses: 'Courses',
  menu_option_review_flows: 'Reviews',
  menu_option_review_rubrics: 'Rubrics',
  menu_option_store_products: 'Products',
  menu_option_store_transactions: 'Transactions',
  menu_option_access_external: 'Access External',
  menu_option_managed_content: 'Content',
  menu_option_project_list: 'Projects',

  pending_confirm: 'Pending confirmation',
  blocked_access: 'Access blocked',
  blocked_access: 'Access blocked',
  register_field_uid: 'uid(offer)',
  register_user: 'Register',
  filter_user: 'Filter',

  register_access_status_blocked: 'Blocked',
  register_access_status_pendingpayment: 'Payment pending',
  register_access_status_paymentfailed: 'Payment failed',
  register_access_status_active: 'Active',
  register_access_status_inactive: 'Inactive',

  register_register_status_notattempted: 'Not started',
  register_register_status_started: 'In progress',
  register_register_status_passed: 'Passed',

  screen_certificate_title: 'Certificate',
  screen_course_title: 'Courses',
  screen_user_title: 'List of users',
  screen_products_title: 'Products',
  screen_course_edit_title: 'Edit course',
  screen_new_product_title: 'Create product',
  screen_transactions_title: 'Transactions',
  screen_schema_new_entity: 'Group definitions',
  screen_managed_content: 'Contents',

  tab_course_categories: 'Categories',
  tab_course_content: 'Content',
  tab_course_information: 'Course Information',
  tab_course_offers: 'Offers',
  tab_user_groups: 'Permissions and groups',
  tab_user_information: 'Information',
  tab_user_offers: 'Offers available',
  tab_user_registers: 'Registrations',
  tab_user_transactions: 'Transactions',
  tab_group_schema: 'Scheme',
  tab_group_schema_scope_project: 'Project',
  tab_group_schema_scope_directory: 'Directory',
  table_header_register_description: 'Description',
  tab_group_schema_category: 'Category',
  tab_group_schema_security: 'Security',
  tab_members_emtity_information: 'Hierarchy',
  tab_members_information: 'Members',

  table_header_register_interaction_end: 'End of interaction',
  table_header_register_interaction_start: 'Start of interaction',
  table_header_register_name: 'table_header_register_name',
  table_header_register_register_end: 'End of offer',
  table_header_register_register_start: 'Offer start',
  table_header_register_uid: 'table_header_register_uid',
  table_header_register_visibility_end: 'End of view',
  table_header_register_visibility_start: 'Visual start',
  table_header_register_course_type: 'Type',
  table_header_register_course_type_formula_score: 'Formula score type',
  table_header_role_type: 'Permission',
  table_header_role_type_description: 'Role',
  table_header_register_course_thumb_url: 'Cover',
  table_header_register_uid: 'uid(offer)',
  table_header_register_name: 'name (offer)',
  table_header_register_course_uid: 'uid(course)',
  table_header_register_course_title: 'name (course)',
  table_header_user_register_uid: 'uid (registration)',
  table_header_user_register_access_status: 'status (access)',
  table_header_user_register_register_status: 'status (registration)',
  table_header_user_register_score: 'score',
  table_header_user_register_created_at: 'created in',
  table_header_user_register_course_uid: 'uid (course)',
  table_header_user_register_course_title: 'name (course)',
  table_header_user_register_register_uid: 'uid(offer)',
  table_header_user_register_register_name: 'name(offer)',
  table_header_user_register_register_description: 'description (offer)',

  table_header_schema_uid: 'uid',
  table_header_schema_name: 'name',
  table_header_schema_public: 'public',
  table_header_schema_self_register: 'self-register',
  table_header_schema_created_at: 'Creation date',
  table_header_schema_updated_at: 'Updated date',
  table_header_schema_type: 'type',
  table_header_schema_scope: 'Scope',
  table_header_schema_limit_depth_in_hierarchy: 'visibility (limit)',

  table_header_product_uid: 'uid',
  table_header_product_name: 'Product name',
  table_header_product_unique_name: 'Unique Id',
  table_header_product_currency: 'Currency',
  table_header_product_created_at: 'Created at',

  table_header_user_transaction_uid: 'uid',
  table_header_user_transaction_external_uid: 'external uid',
  table_header_user_transaction_active: 'Activated',
  table_header_user_transaction_status: 'Status',
  table_header_user_transaction_payment_type: 'Payment type',
  table_header_user_transaction_created_at: 'Created on',
  table_header_user_transaction_updated_at: 'Updated at',

  table_header_transaction_uid: 'uid',
  table_header_transaction_user_uid: 'User UID',
  table_header_transaction_user_name: 'Username',
  table_header_transaction_external_uid: 'external uid',
  table_header_transaction_active: 'Activated',
  table_header_transaction_status: 'Status',
  table_header_transaction_payment_type: 'Payment type',
  table_header_transaction_created_at: 'Created on',
  table_header_transaction_updated_at: 'Updated at',

  table_header_members_user_name: 'Name',
  table_header_members_user_uid: 'user uid',
  table_user_no_item: 'No items',

  transaction_status_paid: 'Paid',
  transaction_status_pendingreview: 'Awaiting review',
  transaction_status_waitingpayment: 'Waiting for payment',
  transaction_status_none: 'Undefined',
  transaction_status_chargedback: 'Charged back',
  transaction_status_refused: 'Rejected',
  transaction_status_authorized: 'Authorized',
  transaction_status_pendingrefund: 'Refund in progress',
  transaction_status_refunded: 'Refunded',
  transaction_status_processing: 'Processing',
  transaction_status_analyzing: 'Under Analysis',
  transaction_status_renewed: 'Renovated',

  transaction_payment_card: 'Credit Card',
  transaction_payment_pix: 'Pix',

  schema_public : "Public",
  schema_scope : "Scope",
  schema_scope_directory: "Directory",
  schema_scope_project: "Project",
  schema_detail_title: 'Registered groups',
  schema_detail_description:
    'The list below shows all the groups registered on the platform. A group can be of the "Category" type, for categorizing resources (files, courses...), or of the "Security" type, in order to control access to a given resource (file, offer...) based on in its members. To create a new group, select the "Create group" option. To create a hierarchy within the group or update the members, select the edit icon.',

  screen_schema_title: 'Groups',
  screen_schema_new_directory: 'Register Schema Directory',
  screen_schema_new_project: 'New group',
  screen_schema_new_access_external: 'New access external',
  screen_schema_edit_access_external: 'Edit New access external',
  screen_new_project: 'New project',
  screen_edit_project: 'Edit Project',
  list_projects_title: 'Projects',
  list_projects_description: 'The following list represents all the projects on the platform.',
  project_new_information_title: 'New Project',
  project_edit_information_title: 'Edit Project',
  project_new_information_description: 'Please add the project information in the form below to create a new registration on the platform.',
  project_edit_information_description: '"Please add the project information in the form below to create a new registration on the platform.',

  modal_user_register_title: 'Register User',
  modal_user_change_access_status_title: 'Access Status',
  modal_user_change_access_status_message: 'Change access status to:',
  modal_user_change_access_status_button: 'Change',

  message_registration: 'Are you sure you want to register user',
  modal_register_user_title: 'Register User',
  modal_register_user_button: 'Enroll',
  modal_register_user_message:
    'Are you sure you want to enroll the user in this offer?',

  modal_edit_transaction_title: 'Edit transaction',
  modal_edit_transaction_message: 'Change transaction status to:',
  modal_edit_transaction_button: 'Change',

  modal_title_add_member: 'Add new member',
  placeholder_search_user: 'Search by uid, name or login',

  title_input_confirm_password: 'title_input_confirm_password',
  title_input_password: 'title_input_password',
  title_user_login: 'Impersonate',
  title_user_transaction: 'New transaction',
  update: 'Update',

  user_blocked_access: 'Access blocked',

  button_update_password: 'Change password',

  user_created_at: 'Creation date',
  user_detail_cpf: 'CPF',
  user_detail_email_main: 'Primary email',
  user_detail_first_name: 'First name',
  user_detail_last_name: 'Surname',
  user_edit_information_button_update_user: 'Update information',
  user_edit_information_description: `In the form below, update the data of the user registered on the platform. In addition to the possibility of blocking access or canceling the need for confirmation of the email registered by the user. In the top menu, access the applications as the user, through the "Personalize" option, or manually change the user's password in "Change password".`,
  user_edit_information_title: 'Details regarding the user',
  user_edit_offers_available_description:
    'In the listing below, all the offers and their respective course that are currently visible for the user to enroll. To register manually, choose the desired offer and click on the edit icon. Any changes are immediately reflected to the user.',
  user_edit_offers_available_title: 'Offers visible to user',
  user_edit_register_linked_description:
    'In the list below, all registrations related to the user, active or not on the platform. If you need to change the status of an enrollment, choose the desired enrollment and click the edit icon. Any changes are immediately reflected to the user.',
  user_edit_register_linked_title: 'Linked registrations',
  user_field_blocked_access: 'blocked',
  user_field_created_at: 'created in',
  user_field_detail: 'user_field_detail',
  user_field_detail_name: 'name',
  user_field_login: 'login',
  user_field_pending_confirm: 'pending confirmation',
  user_field_uid: 'uid',
  user_field_updated_at: 'updated on',
  user_groups_edit_entities_description: `In the list below, there are all registered groups of the "Security" type. Relating the user in a group, it allows a centralized control of the user's permissions on the platform (controlled via group) and mainly in the access control. Groups can be added or removed in "Settings => Groups".`,
  user_groups_edit_entities_title: 'Participating groups',
  user_groups_edit_roles_description: `The default permission is "Viewer", which ensures the user only sees what has been granted. Each permission linked to the user represents a feature or functionality released on the platform. To add or remove new permissions, click the top button "Change permissions".`,
  user_groups_edit_roles_title: 'Permissions attached to user',
  user_login: 'login',
  user_pending_confirm: 'Waiting for email confirmation',
  user_title_screen: 'Edit User',
  user_title_updated_password: 'Change password',
  user_uid: 'uid',
  user_updated_at: 'Updated date',
  user_new_password: 'New password',
  user_edit_transactions: 'Transaction history',
  user_edit_description_transactions: `In the list below, there are all purchases made by the user within the platform. Once a purchase is paid, the release of the resources linked to the product is done automatically. If you want to release a product without the need for payment, select the option "Create fake purchase" in the top menu. Once the purchase is added, the change is immediately reflected to the user.`,
  user_new_password_confirm: 'Confirm new password',
  title_user_roles: 'User Permissions',

  table_user_offers_message_no_item: 'No items in offer table',

  no_result: 'Result not found.',
  modal_node_edit_title: 'Edit Node',
  node_edit_title: 'Learning Unit Edit',
  node_edit_description: 'Learning unit general settings',
  node_thumb: 'Image',
  button_update_thumb: 'Change image',

  node_config_base: 'Configuration',
  node_uid: 'uid',
  node_created_at: 'Created on',
  node_updated_at: 'Updated on',
  node_data_title: 'Title',
  node_data_description: 'Description',
  node_duration: 'Duration',
  node_interaction_interval_start: 'Give access after:',
  node_interaction_interval_end: 'Block access after:',
  node_row: 'Line',
  node_column: 'Column',
  node_products: 'Products',
  button_update_config_base: 'Update',
  node_override_info_title: 'Override offer',
  node_override_info_description: 'Replace node base offer with:',
  node_override_interaction_interval_start: 'Give access after:',
  node_override_interaction_interval_end: 'Block access after:',
  node_override_row: 'Line',
  node_override_column: 'Column',
  node_type: 'Type',
  node_type_tags: 'Tag type',
  node_type_tags_placeholder: 'Enter type',
  node_type_formula_score: 'Formula score type',
  node_type_tags_label_lesson: 'Lesson',
  node_type_tags_label_project: 'Project',
  node_type_tags_label_course: 'Course',
  node_type_tags_label_module: 'Module',

  screen_revision_title: 'Revisions',

  activity_edit: 'Edit Activity',
  activity_edit_title: 'Learning Unit Activity',
  activity_edit_description: 'General activity settings',
  activity_config_base: 'Configuration',

  modal_activity_edit_uid: 'UID',
  modal_activity_edit_name: 'Activity name',
  modal_activity_edit_score_min: 'Minimum score',
  modal_activity_edit_score_max: 'Maximum score',
  modal_activity_edit_score_weight: 'Score weight',
  modal_activity_edit_score_group: 'Group score',
  modal_activity_edit_duration: 'Duration',
  modal_activity_edit_maximum_attempts_after_completed:
    'Maximum attempts after completion',
  modal_activity_edit_maximum_attempts_after_failed:
    'Maximum attempts after failing',
  modal_activity_edit_maximum_attempts_after_passed:
    'Maximum attempts after approval',
  modal_activity_edit_required_predecessor_completion:
    'Require completion of previous activity?',
  modal_activity_edit_content_upload_required:
    'Require user to submit content?',
  modal_button_confirm_delete_node: 'Yes',
  modal_definitions_group: 'Group definitions',

  activity_button_update: 'Update',
  button_change_activity: 'Update activity',
  modal_activity_edit_created_at: 'Created in ',
  modal_activity_edit_updated_at: 'Updated on',

  offer_insert: 'New offer',
  offer_edit: 'Edit offer',
  offer_edit_detail_title: 'Add offer',
  offer_edit_detail_description:
    'Define information, availability, interaction and visibility of the offer',
  offer_edit_entity_title: 'Permission title by entities',
  offer_edit_entity_description: 'Permission description by entities',
  offer_uid: 'uid',
  offer_name: 'Offer name',
  offer_description: 'Description',
  offer_register_start: 'Availability start',
  offer_register_end: 'End of availability',
  offer_visibility_start: 'Visibility start',
  offer_visibility_end: 'End of visibility',
  offer_interaction_start: 'Start of interaction',
  offer_interaction_end: 'End of interaction',

  node_add_node: 'Add node',
  node_add_activity: 'Add activity',

  default_new_activity_name: 'New activity',
  default_new_schema_name: 'New activity',
  default_new_register_name: 'New offer',
  default_message_empty_result: 'No results found',
  default_days: 'days',
  default_hours: 'hours',
  default_minutes: 'minutes',
  default_seconds: 'seconds',
  default_true: 'Yes',
  default_false: 'No',

  success_message_register_update: 'Offer updated successfully',
  pending_message_register_update: 'Updating offer',
  error_message_register_update: 'Failed to update offer',

  success_message_schema_create: 'Group created successfully',
  pending_message_schema_create: 'Creating group',
  error_message_schema_create: 'Failed to create group',

  success_message_register_insert: 'Offer created successfully',
  pending_message_register_insert: 'Creating offer',
  error_message_register_insert: 'Failed to create offer',

  success_message_user_entity_insert: 'User assigned to group successfully',
  pending_message_user_entity_insert: 'Assigning user to group',
  error_message_user_entity_insert: 'Failed to assign user to group',

  success_message_user_entity_delete: 'User successfully removed from group',
  pending_message_user_entity_delete: 'Removing user from group',
  error_message_user_entity_delete: 'Failed to remove user from group',

  success_message_user_info_update: 'Updated user information',
  pending_message_user_info_update: 'Updating user information',
  error_message_user_info_update: 'Failed to update user information',

  success_message_information_course: 'Updated course information',
  pending_message_information_course: 'Updating course information',
  error_message_information_course: 'Failed to update course information',

  success_message_categories_course: 'Updated category info',
  pending_message_categories_course: 'Updating category info',
  error_message_categories_course: 'Failed to update category information',

  success_message_user_password_update: 'User password updated',
  pending_message_user_password_update: 'Updating user password',
  error_message_user_password_update: 'Failed to update user password',
  error_message_user_password_confirmation:
    'Error validating registered passwords',

  success_message_user_register_status_update: 'Updated User Enrollment Status',
  pending_message_user_register_status_update:
    'Updating user enrollment status',
  error_message_user_register_status_update:
    'Failed to update user enrollment status',

  success_message_user_register_insert:
    'User successfully enrolled in the course',
  pending_message_user_register_insert: 'Enrolling user in course',
  error_message_user_register_insert: 'Failed to enroll user in the course',

  success_message_user_add_role: 'Permission successfully assigned to user',
  pending_message_user_add_role: 'Assigning permission to user',
  error_message_user_add_role: 'Failed to assign permission to user',

  success_message_user_remove_role: 'Permission successfully removed from user',
  pending_message_user_remove_role: 'Removing user permission',
  error_message_user_remove_role: 'Failed to remove user permission',

  success_message_schema_delete: 'Group removed',
  pending_message_schema_delete: 'Removing group',
  error_message_schema_delete: 'Failed to remove group',

  pending_message_new_product_insert: 'Adding new product',
  success_message_new_product_insert: 'Product registered successfully',
  error_message_new_product_insert: 'Failed to register product',

  success_message_new_user: 'User registered successfully',
  pending_message_new_user: 'Registering new user',
  error_message_new_user: 'Failed to register new user',

  success_message_user_add_products:
    'Transaction successfully registered for the user',
  pending_message_user_add_products: 'Adding transaction for user',
  error_message_user_add_products: 'Failed to register transaction for user',

  success_message_status_transaction: 'Transaction changed successfully',
  pending_message_status_transaction: 'Changing transaction status',
  error_message_status_transaction: 'Error changing transaction status',

  pending_message_user_remove_member: 'Removing entity member',
  success_message_user_remove_member: 'Member removed',
  error_message_user_remove_member: 'Failed to remove member',

  pending_message_user_add_member: 'Adding entity member',
  success_message_user_add_member: 'Member successfully added',
  error_message_user_add_member: 'Error adding member',

  pending_message_certificate_delete: 'Deleting certificate',
  success_message_certificate_delete: 'Certificate deleted',
  error_message_certificate_delete: 'Failed to remove certificate',

  success_message_created_node: 'Node created successfully',
  error_message_created_node: 'Failed to create node',

  success_message_updated_activity: 'Updated activity information',
  error_message_updated_activity: 'Failed to update activity information',

  success_message_deleted_activity: 'Activity deleted',
  error_message_deleted_activity: 'Failed to remove activity',

  success_message_updated_node: 'Updated node information',
  error_message_updated_node: 'Failed to update node information',

  success_message_deteted_node: 'Node deleted successfully',
  error_message_deteted_node: 'Failed to delete node',

  success_message_create_authorization: 'Authorization created',
  error_message_create_authorization: 'Failed to create an authorization',
  pending_message_create_authorization: 'Creating authorization',

  success_message_updated_authorization: 'Updated authorization',
  error_message_updated_authorization: 'Failed to update an authorization',
  pending_message_updated_authorization: 'Updating Authorization',

  success_message_created_activity: 'Activity created successfully',
  error_message_created_activity: 'failed to create activity',

  success_message_access_external_create:
    'External access created successfully',
  pending_message_access_external_create: 'Creating external access',
  error_message_access_external_create: 'Error creating external access',

  pending_message_access_external_delete: 'Removing access external',
  success_message_access_external_delete: 'Access external removed',
  error_message_access_external_delete: 'Falided to remove member',

  pending_message_create_new_project: 'Creating project',
  success_message_create_new_project: 'Project created',
  error_message_create_new_project: 'Failed to create an project',

  select_edit_node_register_unselected: 'No offers selected',

  new_product_name: 'Name',
  new_product_currency: 'Currency',
  new_product_amount: 'Amount',
  new_product_installments_max: 'Installments',
  new_product_unique_name: 'Unique Product ID',
  new_product_installments: 'Installments',
  new_product_installments_placeholder: 'Enter the installment',

  information_new_product_name: 'Enter product name',
  information_new_product_currency:
    'Enter the currency symbol for which the product will be traded. Examples: R$ to real, US$ to US dollar',
  information_new_product_amount:
    'Enter the price at which the product will be traded',
  information_new_product_unique_name:
    'Fill in the unique product identification with alphanumeric and/or special characters, without spaces',
  information_new_product_installments_max:
    'Enter the maximum number of installments allowed',

  success_message_course_delete: 'Course removed successfully',
  pending_message_course_delete: 'Removing course',
  error_message_course_delete: 'Failed to remove course',

  success_message_course_clone: `Course successfully`,
  pending_message_course_clone: 'Cloning course',
  error_message_course_clone: 'Failed to clone cours',

  pending_message_create_new_content: 'Creating content',
  success_message_create_new_content: 'content created',
  error_message_create_new_content: 'Failed to create content',

  pending_message_update_new_content: 'Updating content',
  success_message_update_new_content: 'Content updated',
  error_message_update_new_content: 'Failed to update content',

  pending_message_update_new_category: 'Updating category',
  success_message_update_new_category: 'Category updated',
  error_message_update_new_category: 'Failed to update category',

  pending_message_create_new_activity: 'Creating activity',
  success_message_create_new_activity: 'Activity created',
  error_message_create_new_activity: 'Failed to create activity',

  pending_message_update_new_activity: 'Updating activity',
  success_message_update_new_activity: 'Activity updated',
  error_message_update_new_activity: 'Failed to update activity',

  pending_message_access_delete_activity: 'Removing activity',
  success_message_access_delete_activity: 'Activity removed',
  error_message_access_delete_activity: 'Falided to activity',

  select_edit_node_register_unselected: 'No offers selected',

  role_type_inherited: 'Inherited',
  role_type_anonymous: 'Anonymous',
  role_type_viewer: 'Viewer',
  role_type_platformsuper: 'Superuser',
  role_type_platformadministrator: 'Platform Administrator',
  role_type_administratorglobal: 'Global administrator',
  role_type_contributorglobal: 'Global contributor',
  role_type_readerglobal: 'Global reader',
  role_type_system: 'System',
  role_type_guideadmin: 'Guide admin',
  role_type_guidecontributor: 'Guide contributor',
  role_type_templateadmin: 'Template Admin',
  role_type_templatecontributor: 'Template Contributor',
  role_type_templateviewer: 'Template Viewer',
  role_type_learningadmin: 'Learning Administrator',
  role_type_learningcontributor: 'Learning Contributor',
  role_type_reviewadmin: 'Review admin',
  role_type_reviewcontributor: 'Review contributor',
  role_type_useradmin: 'User Admin',
  role_type_schemaadmin: 'Schema Administrator',
  role_type_schemacontributor: 'Schema Contributor',
  role_type_entityadmin: 'Entity Administrator',
  role_type_entitycontributor: 'Entity contributor',
  role_type_learninguseradmin: 'Learning User Administrator',
  role_type_socialmediaadmin: 'Social Media Administrator',
  role_type_socialmediacontributor: 'Social Media Contributor',
  role_type_userimpersonate: 'User impersonation',

  role_description_type_inherited: '',
  role_description_type_anonymous: '',
  role_description_type_viewer: 'Limited access to platform features. Lowest permission of an authenticated user.',
  role_description_type_platformsuper: 'Access to all resources and projects registered on the platform.',
  role_description_type_platformadministrator: 'Acesso a todos os recursos e projetos cadastrados na plataforma.',
  role_description_type_administratorglobal: 'Access to all resources and projects registered on the platform.',
  role_description_type_contributorglobal: 'Access to all linked project resources.',
  role_description_type_readerglobal: '',
  role_description_type_system: '',
  role_description_type_guideadmin: '',
  role_description_type_guidecontributor: '',
  role_description_type_templateadmin: '',
  role_description_type_templatecontributor: '',
  role_description_type_templateviewer: '',
  role_description_type_learningadmin: '',
  role_description_type_learningcontributor: '',
  role_description_type_reviewadmin: '',
  role_description_type_reviewcontributor: '',
  role_description_type_useradmin: '',
  role_description_type_schemaadmin: '',
  role_description_type_schemacontributor: '',
  role_description_type_entityadmin: 'Permission to add and edit users',
  role_description_type_entitycontributor: '',
  role_description_type_learninguseradmin: '',
  role_description_type_socialmediaadmin: '',
  role_description_type_socialmediacontributor: '',
  role_description_type_userimpersonate: '',
  role_description_type_notificationadmin: '',
  role_description_type_managedcontentadmin: 'Full permission involving learning content.',
  role_description_type_managedcontentcontributor: 'Permission to edit content metadata, update notes, add and remove products, and add and remove permissions.',

  rubric_create: 'Create Rubric',
  rubric_uid: 'UID',
  rubric_title: 'Title',
  rubric_title_placeholder: 'Enter Rubric Title',
  rubric_description: 'Description',
  rubric_description_placeholder: 'Enter the Description of the Rubric',
  rubric_criterion: 'Criterion',
  rubric_value: 'Value',
  rubric_value_description: 'Description Value',
  rubric_created_at: 'Creation date',
  rubric_updated_at: 'Updated date',
  rubric_delete: 'Delete',

  schema_name: 'Name',
  schema_type: 'Group type',
  schema_limit_depth_in_hierarchy: 'Limit entity visibility over its children',
  schema_properties: 'Required properties of entities',
  schema_tags: 'Tags',
  schema_option_category: 'Category',
  schema_option_security: 'Security',

  entity_uid: 'Uid',
  entity_name: 'Name',
  entity_row: 'Row',
  entity_column: 'Column',
  entity_data_name: 'Name',
  entity_roles: 'Roles',
  entity_created_at: 'Creation date',
  entity_updated_at: 'Updated date',

  certificate_type: 'Certificate Template',
  certificates_detail_title: 'Registered certificates',
  certificates_detail_description: `Below, all the certificate models registered on the platform. Once the certificate is registered, it will be available for use within the course offerings. To create a new certificate, select the option "Create certificate".`,

  table_header_certificate_title: 'Certificate',
  table_header_certificate_document_created_at: 'Creation date',
  table_header_certificate_document_last_modified: 'Update date',

  schema_new_title: 'New group definitions',
  schema_new_description: `Define a name and desired type for the new cluster. The hierarchy related to the group will be registered in the next step, now, just specify the desired attributes for each subgroup. Example: In a group with the objective of controlling users based on different units of a school, where each unit would have a name and a CPNJ for control in reports, we would have the following configuration:\nA group of type "Security", with two text type properties, "Name" and "CNPJ".`,

  access_external_ip_range: 'Range ip',
  access_external_type: 'Type',
  access_external_project: 'Project',
  access_external_entity: 'Entity',
  access_external_new_create: 'Create new access external',
  access_external_uid: 'uid',
  access_external_ip_range_filter: 'Range ip',
  access_external_created_at: 'Creation date',
  access_external_updated_at: 'Update date',
  access_external_title: 'Defining the new external access',
  access_external_description: 'The following listing represents the externally accessible IP addresses based on the rule applied in the app.',

  table_access_external_no_item: 'No item',

  project_field_uid: 'UID',
  project_field_name: 'Name',
  project_field_created_at: 'Creation date',
  project_field_updated_at: 'Updated date',
  button_new_content_group: 'New Content',
  content_group_field_uid: 'Uid',
  content_group_field_name: 'Name',
  content_group_field_thumb_url: 'Miniature',
  content_group_field_created_at: 'Created in',
  content_group_field_updated_at: 'Updated at',

  screen_managed_content_edit: 'Managed Content - Edit',
  managed_content_uid: 'Uid',
  managed_content_name: 'Name',
  managed_content_description: 'Description',

  ///managed/content/group/uid/<uid>
  tab_managed_content_informations: 'Informations',
  tab_managed_content_categories: 'Categories',
  tab_managed_content_contents: 'Contents',
  tab_managed_content_preview: 'Preview',
  tab_managed_content_products: 'Products',
  tab_managed_content_authorizations: 'Authorizations',

  managed_content_content_title: 'Referenced contents',
  managed_content_content_description:
    "Managed content is made up of one or more files. Therefore, select the desired files that should be referenced by this content group, clicking 'New reference'.",

  managed_content_detail_title: 'Content group',
  managed_content_detail_description:
    'Basic information about registered content. Your change reflects into production after approximately 10 minutes.',
  managed_content_created_at: 'Created in',
  managed_content_updated_at: 'Updated at',
  managed_content_categories_title: 'Content categorization',
  managed_content_categories_description:
    'Below, select all the categories that the content is in. With properly categorized content, it will be easier to target the user.',

  managed_content_products_title: 'Product link',
  managed_content_products_description:
    'The list below represents all products registered on the platform. Select all products that grant user access to content. If the user has one of the selected products, access to the content will be authorized.',

  managed_content_button_link_content: 'New reference',

  button_update_managed_content: 'Update',
  update_managed_content: 'Update',

  table_header_managed_content_content_uid: 'Uid',
  table_header_managed_content_content_name: 'Name',
  table_header_managed_content_content_mime_type: 'Content type',
  table_header_managed_content_content_description: 'Description',
  table_header_managed_content_content_created_at: 'Created at',
  table_header_managed_content_content_updated_at: 'Updated at',

  managed_content_reference_modal_title_new: 'Referenced content',

  login_finding_user : 'finding login...',
  login_user_not_found : 'login not found',
  login_processing : 'authenticating...',
  login_error : 'failed to authenticate',
};

export { textsEN };