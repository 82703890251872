import React, { Component } from 'react';
import SDK from 'api.digitalpages.module.sdk.api';
// import { LoginService } from '../../services/login';
import { UserService } from '../../services/user';
import './header.scss';
import Logo from "../../assets/images/logo_instituto-jef.jpg"
import LogoDp from "./logo-dp.png"

import Loading from '../loading';
import RDP_UTILS from '../../config/util';
import Sdk from 'api.digitalpages.module.sdk.api';
import { setLanguage, textFromUid } from '../../utils/textUtilities';

export default class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      username: null,
      projects: [],
      active_project: null,
      active_directory: null,
      active_user_dropdown: false,
      active_projects_dropdown: false,
      active_translation_dropdown: false,
      active_directies_dropdown: false,
      displayed_preloader: false,
    };

    this.updateUserAccess = this.updateUserAccess.bind(this);
  }

  async componentDidMount() {
    await this.updateUserAccess();

    SDK.authorization.addUserInfoListen(this.updateUserAccess);
    SDK.authorization.addActiveProjectListen(this.updateUserAccess);
  }

  async updateUserAccess() {
    const actProject = SDK.authorization.activeProject;
    const { projects } = SDK.authorization;

    let userName = null;

    if (SDK.authorization.user) {
      if (SDK.authorization.user.detail) {
        userName = SDK.authorization.user.detail.name;
      } else {
        // versão antiga da API
        userName = SDK.authorization.user.name;
      }
    }

    this.setState({
      username: userName !== null ? userName : null,
      projects: projects || [],
      active_project: actProject || null,
      active_directory: null,
    });
  }

  async logout() 
  {
    this.toggleUserDropdown();
    await SDK.authorization.logout();
    RDP_UTILS.redirectTo('login');
  }

  async changeProject(project) {
    this.setState({
      displayed_preloader: true,
    });

    await SDK.authorization.setActiveProject(project);
    // window.location.reload();
  }

  async changeDirectory(directory) {
    this.setState({
      displayed_preloader: true,
    });

    await SDK.authorization.signinVirtualInDirectory(directory.uid);

    this.setState({ displayed_preloader: false });

    await this.updateUserAccess();
  }

  renderProjectsDropdown() {
    const _this = this;
    const activeProject = this.state.active_project;
    const { projects } = this.state;

    // todo:ajustar posição do popup
    if (this.state.active_projects_dropdown === true) {
      return (
        <div className="rdp-analytics-header-items-dropdown-container">
          <div
            className="rdp-analytics-header-items-dropdown-overlay"
            onClick={() => this.toggleProjectsDropdown()}
          />
          <ul className="rdp-analytics-header-items-dropdown-popup">
            {projects.map((project, index) => (
              <li
                key={`${index}_pro`}
                className={`rdp-analytics-header-items-dropdown-popup-name ${
                  activeProject && activeProject.uid === project.uid
                    ? 'active'
                    : ''
                }`}
                onClick={() => _this.changeProject(project)}
              >
                {project.name}
              </li>
            ))}
          </ul>
        </div>
      );
    }
  }

  onSelectLanguage(language) {
    setLanguage(language);

    window.location.reload();
  }

  renderTranslationDropdown() {
    const _this = this;

    if (this.state.active_translation_dropdown === true) {
      return (
        <div className="rdp-analytics-header-items-dropdown-container">
          <div
            className="rdp-analytics-header-items-dropdown-overlay"
            onClick={() => this.toggleTranslationDropdown()}
          />
          <ul className="rdp-analytics-header-items-dropdown-popup">
            <li
              className={`rdp-analytics-header-items-dropdown-popup-name ${
                true ? '' : ''
              }`}
              onClick={() => _this.onSelectLanguage('pt_br')}
            >
              {textFromUid('header_translation_pt')}
            </li>
            <li
              className={`rdp-analytics-header-items-dropdown-popup-name ${
                true ? '' : ''
              }`}
              onClick={() => _this.onSelectLanguage('en_us')}
            >
              {textFromUid('header_translation_en')}
            </li>
          </ul>
        </div>
      );
    }
  }

  toggleProjectsDropdown() {
    this.setState({
      active_projects_dropdown: !this.state.active_projects_dropdown,
      active_directies_dropdown: false,
    });
  }

  toggleTranslationDropdown() {
    this.setState({
      active_translation_dropdown: !this.state.active_translation_dropdown,
      active_directies_dropdown: false,
    });
  }

  toggleDirectoriesDropdown() {
    this.setState({
      active_directies_dropdown: !this.state.active_directies_dropdown,
      active_projects_dropdown: false,
    });
  }

  renderUserDropdown() {
    if (this.state.active_user_dropdown === true) {
      return (
        <div className="rdp-analytics-header-user-dropdown-container">
          <div
            className="rdp-analytics-header-user-dropdown-overlay"
            onClick={() => this.toggleUserDropdown()}
          />
          <div className="rdp-analytics-header-user-dropdown-popup">
            <span
              className="rdp-analytics-header-user-dropdown-signout"
              onClick={() => this.logout()}
            >
              Logout
            </span>
          </div>
        </div>
      );
    }
  }

  toggleUserDropdown() {
    this.setState({
      active_user_dropdown: !this.state.active_user_dropdown,
    });
  }

  renderPreloader() {
    if (this.state.displayed_preloader === true) {
      return <Loading globalLoading msg="RDP Manager - Loading Project ..." />;
    }
  }

  render() {
    const activeProjectName =
      this.state.active_project !== null ? this.state.active_project.name : '';
    this.renderPreloader();

    return (
      <div className="rdp-admin-component-header">
            <div className="container-logo"> 
          {/* <div className="rdp-analytics-login-sidebar-logo" /> */}
          <img src={Logo} />
        </div>
        {/* <div className="rdp-admin-component-header-logo" /> */}
        {/* <span className="rdp-analytics-header-active-item" onClick={() => this.toggleProjectsDropdown()}>{activeProjectName}</span> */}

        <div className="rdp-analytics-header-options">
          <div
            className="rdp-analytics-header-active-item"
            onClick={() => this.toggleProjectsDropdown()}
          >
            <span className="rdp-analytics-header-active-item-label">
              {textFromUid('header_actived_project')}
            </span>
            <span className="rdp-analytics-header-active-item-name">
              {activeProjectName}
            </span>
            {this.renderProjectsDropdown()}
          </div>
        </div>

        <div className="rdp-analytics-header-options-translation">
          <div
            className="rdp-analytics-header-active-item"
            onClick={() => this.toggleTranslationDropdown()}
          >
            <span className="rdp-analytics-header-active-item-label">
              {textFromUid('header_translation')}
            </span>
            <span className="rdp-analytics-header-active-item-name">
              {window.localStorage.getItem('rdp-manager-current-language') ===
              'en_us'
                ? textFromUid('header_translation_en')
                : textFromUid('header_translation_pt')}
            </span>
            {this.renderTranslationDropdown()}
          </div>
        </div>

        <div
          className="rdp-analytics-header-active-user"
          onClick={() => this.toggleUserDropdown()}
        >
          <span className="rdp-analytics-header-active-user-avatar" />
          <span className="rdp-analytics-header-active-user-hello">
            {textFromUid('header_name_user')}
          </span>
          <span className="rdp-analytics-header-active-user-name">
            {this.state.username}
          </span>
        </div>

        {this.renderUserDropdown()}
        <div className='logo-dp'>
          <img src={LogoDp} />
        </div>
      </div>
    );
  }
}
