import React, { Component } from 'react';
import './table-dynamic.scss';

import TableTrDynamic from './table-tr-dynamic';
import TableOrderArrows from '../table-order-arrows/table-order-arrows';

const IGNORED_TYPES = ['webpack', 'table'];

export default class TableDynamic extends Component {
  getActiveArrow(column) {
    const { filters } = this.props;

    if (filters.order_field === column.identifier) {
      if (filters.order_type === 'asc') {
        return 'up';
      }

      if (filters.order_type === 'desc') {
        return 'down';
      }
    }

    return undefined;
  }

  definedHead() {
    const {
      hasCheckbox,
      hasView,
      hasDelete,
      hasEdit,
      hasClone,
      hasViewList,
      headers,
      onFilterChange,
      defaultFields,
      data,
    } = this.props;

    const fields = [...headers, ...defaultFields];

    if (!data || !data.length) {
      return null;
    }

    return (
      <thead>
        <tr>
          {hasCheckbox && (
            <th className="text-center">
              <input
                className="td-checkbox-all"
                type="checkbox"
                onClick={(e) => this.checkAll(e)}
              />
            </th>
          )}

          {fields.map((th, i) => {
            if (!IGNORED_TYPES.includes(th.type)) {
              if (th.field !== undefined) {
                return (
                  <th
                    key={i}
                    onClick={() => onFilterChange && onFilterChange(th)}
                  >
                    <div className="rdp-dynamic-table-th">
                      {onFilterChange && (
                        <TableOrderArrows
                          activeArrow={this.getActiveArrow(th)}
                        />
                      )}

                      <span>{th.field}</span>
                    </div>
                  </th>
                );
              }
            }
          })}

          {hasViewList && <td className="size-small" />}

          {hasView && <td className="size-small" />}

          {hasClone && <td className="size-small" />}

          {hasEdit && <td className="size-small" />}

          {hasDelete && <td className="size-small" />}
        </tr>
      </thead>
    );
  }

  definedBody() {
    const {
      onUpdate,
      onDelete,
      onClone,
      handleView,
      hasCheckbox,
      hasDelete,
      hasEdit,
      hasViewList,
      hasView,
      hasClone,
      onCheckboxClick,
      headers,
      data,
      defaultFields,
    } = this.props;
    const { title, configuration } = this.props;
    return (
      <tbody>
        {data &&
          data.map((tr, i) => (
            <TableTrDynamic
              configuration={configuration}
              key={i}
              hasCheckbox={hasCheckbox}
              hasEdit={hasEdit}
              hasClone={hasClone}
              hasDelete={hasDelete}
              hasViewList={hasViewList}
              hasView={hasView}
              headers={headers.filter(
                ({ type }) => !IGNORED_TYPES.includes(type)
              )}
              defaultFields={defaultFields}
              tr={tr}
              title={title}
              onCheckboxClick={(elementId) => onCheckboxClick(elementId)}
              onUpdate={onUpdate}
              onDelete={onDelete}
              onClone={onClone}
              handleView={handleView}
            />
          ))}
      </tbody>
    );
  }

  render() {
    return (
      <div className="rdp-admin-component-table-dynamic">
        <table className="table-dynamic">
          {this.definedHead()}
          {this.definedBody()}
        </table>
      </div>
    );
  }

  checkAll(action) {
    const { onCheckBoxCheckAll, data } = this.props;
    const elements = [];

    const op = action.target.checked;
    const inputs = document.querySelectorAll(
      'input[type=checkbox].td-checkbox'
    );
    for (let i = 0; i < inputs.length; i++) {
      inputs[i].checked = op;
    }

    if (op) {
      for (let i = 0; i < data.length; i++) {
        elements.push(data[i].uid);
      }
      onCheckBoxCheckAll('add-check', elements);
    } else {
      onCheckBoxCheckAll('uncheck-all');
    }
  }
}
